import React from 'react';
import { Fragment } from 'react';

export default function Terms34(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                34.
            </span>
            Disclaimer 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point is not responsible for any content, code, or any other imprecision.  
            </p> 
            <p className="paragraph p-adjustment text-left">
            By agreeing to our Terms you expressly understand and agree that your access to and use of our App, Website and Services is at your sole risk, and without warranties or guarantees of any kind, whether express or implied.
            </p>
            <p className="paragraph p-adjustment text-left">
            In no event shall Invoice Point be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence, or other torts, arising out of or in connection with the use of the Services or the contents of the Services.  
            </p>
            <p>
            The Company reserves the right to make additions, deletions, or modifications to the contents in the App, on the Website or of the Services at any time without prior notice. We are not responsible for failure to store, misdelivery or untimely delivery of any information or material. We are also not responsible for any harm that might result from downloading or accessing any information or material through our App or Website.
            </p>
            <p className="paragraph p-adjustment text-left">
            Invoice Point is a distributor and not a publisher of the content supplied by third parties; as such, Invoice Point exercises no editorial control over such content and makes no warranty or representation as to the accuracy, reliability, or currency of any information, content, service or merchandise provided through or accessible via the Invoice Point Services. 
            </p>
            <p className="paragraph p-adjustment text-left">
            Without limiting the foregoing, Invoice Point specifically disclaims all warranties and representations in any content transmitted on or in connection with the Invoice Point Services or on sites that may appear as links on the Invoice Point Services, or in the products provided as a part of, or otherwise in connection with, the Invoice Point Services, including without limitation any warranties of merchantability, fitness for a particular purpose or non-infringement of third party rights. No oral advice or written information given by Invoice Point or any of its affiliates, employees, officers, directors, agents, or the like will create a warranty. 
            </p>
            <p className="paragraph p-adjustment text-left">
            Price and availability information is subject to change without notice. Without limiting the foregoing, Invoice Point does not warrant that the Invoice Point Services will be uninterrupted, uncorrupted, timely, or error-free.
            </p>
            <p className="paragraph p-adjustment text-left">
            You acknowledge that the foregoing limitations are an essential element of these Terms and a reasonable allocation of risk between the parties and that in the absence of such limitations the pricing and other provisions in these Terms would be substantially different.
            </p>
            <p className="paragraph p-adjustment text-left">
            Invoice Point makes no representations that the Services are appropriate or available for use in other locations. Those who access or use the Services from other jurisdictions do so based on their own choice and are entirely responsible for compliance with all applicable laws.
            </p>
            <p className="paragraph p-adjustment text-left">
            Some countries' or other jurisdictions do not allow exclusion or limitation of liability for consequential or incidental damages in contracts with consumers, therefore some or all of the above exclusions may not apply to you.
            </p>
        </div>
        </Fragment>
    )
}