import React from 'react';
import { Fragment } from 'react';

export default function Payment3(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                03.
            </span>
            Payment Methods 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ul>
                <li>
                <b>Using Stripe:</b>
                <ol>
                    <li>
                    <b>Setting Up Stripe Integration:</b> Integrate your Invoice Point account with Stripe, a renowned global payment processing platform. Enjoy seamless, secure, and efficient payment collection.
                    </li>
                    <li>
                    <b>Benefits of Using Stripe:</b> Stripe offers real-time transaction monitoring, robust security measures, and a wide range of accepted payment methods, enhancing your payment processing capabilities.
                    </li>
                    <li>
                    <b>Security Measures:</b> Rest assured that your payment data is handled with the utmost care, featuring state-of-the-art encryption and tokenization.
                    </li>
                </ol>
                </li>
                <li>
                <b>Other Payment Options:</b>
                <ol>
                    <li>
                    <b>Alternative Payment Methods Available:</b> In regions where Stripe's direct charge feature applies, primarily non-SEPA countries, the primary payment options are limited to debit and credit cards. This is due to Stripe's regional restrictions, as detailed in their documentation.
                    </li>
                    <li>
                    <b>Apple & Google Pay:</b> Notably, Apple Pay and Google Pay, grounded in local banking infrastructure, transcend regional limitations. These services are available for both SEPA and non-SEPA countries, providing versatile payment options for your clients.
                    </li>
                </ol>
                </li>
            </ul>
            </p>
        </div>
        </Fragment>
    )
}