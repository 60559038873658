import React from 'react';
import { Fragment } from 'react';

export default function Terms19(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                19.
            </span>
            Indemnification  
            </h4>
            <p className="paragraph p-adjustment text-left">
            You agree to indemnify and hold Invoice Point and its parents, subsidiaries, affiliates, officers, employees, agents, partners, and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the App, the Website and the Services; (b) violation of these Terms or any law or regulation; or (c) violation of any right of a third party. 
            </p>
        </div>
        </Fragment>
    )
}