import React from 'react';
import { Fragment } from 'react';

export default function Terms27(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                27.
            </span>
            Intellectual Property 
            </h4>
            <p className="paragraph p-adjustment text-left">
            The App, the Website and their entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned by Invoice Point, its licensors or other providers of such material and are protected by the Netherlands and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded, or distributed in any way, in whole or in part, without the express prior written permission of Invoice Point, unless and except as is expressly provided in these Terms. Any unauthorised use of the material is prohibited.   
            </p>
        </div>
        </Fragment>
    )
}