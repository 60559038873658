import React, { useEffect, useState } from 'react';
import Question1 from './Questions/q1';
import Question2 from './Questions/q2';
import Question3 from './Questions/q3';
import Question4 from './Questions/q4';
import Question5 from './Questions/q5';
import Question6 from './Questions/q6';
import Question7 from './Questions/q7';
import Question8 from './Questions/q8';
import Question9 from './Questions/q9';
import Question10 from './Questions/q10';
import Question11 from './Questions/q11';
import Question12 from './Questions/q12';
import Question13 from './Questions/q13';
import Question14 from './Questions/q14';
import Question15 from './Questions/q15';
import Question21 from './Questions/q21';
import { Fragment } from 'react';
import Footer from '../Footer/Footer';
import Loader from '../../loader/Loader';

function PrivacyMain(){
    useEffect(() => {
        document.title = "Privacy Policy | InvoicePoint"
   }, []);
   const[loader, SetLoader] = useState(false)
    useEffect(() =>
    setTimeout(() =>{
        SetLoader(true)
    },1500)[loader]  
    )
    if (!loader) {
        return <Loader/>;
    }
    return(
        <Fragment>
            <section className="sp-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                    <h1 className="h-1 h-bold text-left">
                        Invoice Point Privacy Policy
                    </h1>
                    <p className="paragraph p-adjustment text-left">
                        Last Updated on 2024-02-09
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Invoice Point (hereinafter: “Invoice Point”, “we”, “our”, “us”) is a company registered in the Netherlands, with a corporate address: De Specerij 25, 1313NC Almere and registration number: 65776852, which manages the Website, the App and all the Services provided therein. 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Invoice Point assumes the role of the 'data controller' with respect to your personal information, as utilized in accordance with the objectives outlined herein. Unless expressly indicated otherwise, Invoice Point holds responsibility for the acquisition, utilization, disclosure, retention, and safeguarding of your Personal Data, adhering to our established privacy standards, this Privacy Policy, and relevant legal statutes.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    The Invoice Point Application is designed and intended for use by individuals and businesses seeking to streamline their invoice and estimate management processes. Our primary target audience includes freelancers, entrepreneurs, small and medium-sized enterprises (SMEs), and professionals seeking efficient and user-friendly tools for creating, customizing, and managing invoices and estimates. While our services are accessible to a broad user base, it is imperative that users understand the intended audience and utilize the Application in a manner consistent with its designed purpose. Invoice Point is not intended for use by individuals under the age of 18, and we do not knowingly collect personal information from users in this age group. Users falling outside the specified target audience are encouraged to review and comply with the Application's terms of service and privacy policy to ensure appropriate usage and adherence to legal and regulatory requirements.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    This data privacy information applies to all users (together “you”, “your” or “Users”) of the Invoice Point Website, its associated subdomains and our application (collectively, our “Services”).
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    We at Invoice Point value your privacy and would therefore like to explain how we collect, use, store and disclose some of your personal information. In order to ensure that you are properly informed we encourage you to carefully read this Privacy Policy.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Our Privacy Policy applies to all Users as defined in our standard Terms, unless the context indicates otherwise. Our Terms and Conditions are accessible at <a href='/terms-of-service'>https://invoicepoint.app/terms-of-service</a>.
                    </p>
                        <Question1 />
                        <Question2 />
                        <Question3 />
                        <Question4 />
                        <Question5 />
                        <Question6 />
                        <Question7 />
                        <Question8 />
                        <Question9 />
                        <Question10 />
                        <Question11 />
                        <Question12 />
                        <Question13 />
                        <Question14 />
                        <Question15 />
                        <Question21 />
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        </Fragment>
    )
}
export default PrivacyMain;