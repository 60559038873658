import React from 'react';
import { Fragment } from 'react';

export default function Terms24(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                24.
            </span>
            Waiver  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute waiver of any subsequent breach. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under these Terms shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under these Terms preclude further exercise of that or any other right granted herein. In the event of a conflict between these Terms and any applicable purchase or other terms, the terms of these Terms shall govern.  
            </p>
            <p className="paragraph p-adjustment text-left">
            Any waiver of any provision of these Terms will be effective only if in writing and signed by an authorised representative of Invoice Point. 
            </p>
        </div>
        </Fragment>
    )
}