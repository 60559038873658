import React from 'react';
import { Fragment } from 'react';

export default function Terms8(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                08.
            </span>
            Restrictions on User Conduct 
            </h4>
            <p className="paragraph p-adjustment text-left">
            You agree that you will not violate any law, contract, intellectual property or other third party right, and that you are responsible for your own conduct, while accessing or using the App or the Website. You are also responsible for any consequences that might follow from that. You also agree that you will use the App and the Website in accordance with these Terms and any applicable laws or regulations.
            </p>
            <p className="paragraph p-adjustment text-left">
            You warrant and agree that you will not (or permit any other person or entity) amongst other:
            <ul>
                <li>
                provide false or misleading information to Invoice Point;
                </li>
                <li>
                pretend to be another person;
                </li>
                <li>
                email or otherwise upload any content that:
                <ul>
                    <li>
                    violates intellectual property or other proprietary rights of any party;
                    </li>
                    <li>
                    no law, contract or fiduciary relationship gives you the right to upload it; 
                    </li>
                    <li>
                    contains software viruses or any other similar technologies that could interrupt, destroy or limit the functionality of computer software, hardware or telecommunications equipment;
                    </li>
                    <li>
                    represents a privacy or security risk to any person;
                    </li>
                    <li>
                    represents e.g. unauthorised advertising, promotional materials and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation; 
                    </li>
                    <li>
                    is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise unacceptable; 
                    </li>
                    <li>
                    is considered inappropriate by Invoice Point;
                    </li>
                    <li>
                    restricts/inhibits others from using our App or Website; or
                    </li>
                    <li>
                    might expose Invoice Point or its Users to any harm or liability;
                    </li>
                </ul>
                </li>
                <li>
                interfere with or disrupt our App, Website or servers, or disobey any requirements, procedures, policies or regulations of networks connected to our App or Website;
                </li>
                <li>
                violate any applicable local, state, national or international law, or any other regulations having the force of law, or which would involve proceeds of any unlawful activity; 
                </li>
                <li>
                engage in, encourage or promote any activity that violates these Terms;
                </li>
                <li>
                gather email addresses or other contact information of other Users of the App or  Website to send them unsolicited emails or other such communications;
                </li>
                <li>
                attain or try to get access to any materials or information through means that are not intentionally made available or provided for through our App or Website;
                </li>
                <li>
                use any robot, spider, crawler, script or other means not authorised by us to retrieve or index our App or Website or anything on it;
                </li>
                <li>
                access or use our App or Website in order to create a product or service that is competitive with ours; 
                </li>
                <li>
                modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the App or the Website; 
                </li>
                <li>
                license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the App or the Website or make the Services available to any third party; 
                </li>
                <li>
                remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Invoice Point or its affiliates, partners, suppliers, or the licensors of the website/app;
                </li>
                <li>
                use our App or Website in a way that could negatively affect its functioning or how others use it.
                </li>
            </ul>
            </p>
        </div>
        </Fragment>
    )
}