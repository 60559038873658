import React, { Fragment, useEffect } from 'react';
import animationFunction from '../Common/animationFunction';
const data = [

    {
        id:1,
        planName: "Basic",
        price:2.99,
        planDescription:"For small business owners looking for more automation and insights",
        features:[
            {
                id:0,
                img:"/images/Group65.png",
                feature:"3 Invoices / Estimates / Credit Notes"
            },
            {
                id:3,
                img:"/images/Group65.png",
                feature:"3 Clients"
            },
            {
                id:8,
                img:"/images/Group65.png",
                feature:"20 Items / Services"
            },
            {
                id:8,
                img:"/images/Group65.png",
                feature:"20 Expenses"
            },
            {
                id:6,
                img:"/images/Group65.png",
                feature:"20 Time Trackings"
            },
            {
                id:7,
                img:"/images/Group65.png",
                feature:"Invoice Status Tracking"
            },
            {
                id:9,
                img:"/images/Group65.png",
                feature:"Client Activity"
            },
            {
                id:10,
                img:"/images/Group65.png",
                feature:"Reports"
            },
        ]
    },
    {
        id:2,
        planName: "Premium",
        price:4.99,
        planDescription:"For business owners with more intricate needs",
        features:[
            {
                id:0,
                img:"/images/Group65.png",
                feature:"10 Invoices / Estimates / Credit Notes"
            },
            {
                id:3,
                img:"/images/Group65.png",
                feature:"10 Clients"
            },
            {
                id:8,
                img:"/images/Group65.png",
                feature:"50 Items / Services"
            },
            {
                id:8,
                img:"/images/Group65.png",
                feature:"50 Expenses"
            },
            {
                id:6,
                img:"/images/Group65.png",
                feature:"50 Time Trackings"
            },
            {
                id:7,
                img:"/images/Group65.png",
                feature:"Invoice Status Tracking"
            },
            {
                id:9,
                img:"/images/Group65.png",
                feature:"Client Activity"
            },
            {
                id:10,
                img:"/images/Group65.png",
                feature:"Reports"
            },
        ]
    },
    {
        id:3,
        planName: "Unlimited",
        price:19.99,
        planDescription:"For large businesses looking for more insights and special features",
        features:[
            {
                id:0,
                img:"/images/Group65.png",
                feature:"Unlimited Invoices / Estimates / Credit Notes"
            },
            {
                id:3,
                img:"/images/Group65.png",
                feature:"Unlimited Clients"
            },
            {
                id:8,
                img:"/images/Group65.png",
                feature:"Unlimited Items / Services"
            },
            {
                id:8,
                img:"/images/Group65.png",
                feature:"Unlimited Expenses"
            },
            {
                id:6,
                img:"/images/Group65.png",
                feature:"Unlimited Time Trackings"
            },
            {
                id:7,
                img:"/images/Group65.png",
                feature:"Invoice Status Tracking"
            },
            {
                id:9,
                img:"/images/Group65.png",
                feature:"Client Activity"
            },
            {
                id:10,
                img:"/images/Group65.png",
                feature:"Reports"
            },
        ]
    },
    
]


function PlanBox(){
    useEffect(() => {
        animationFunction('planBox')
    })
    return(
        <Fragment>
            {data.map((i => (
                <div className="col-lg-4" key={i.id}>
                <div className="planBox mb-20 p-30 br-8">
                        <h6 className="h-6 mb-15 fw-300">
                            {i.planName}
                        </h6>
                        <h3 className="h-3 fw-400 mb-15">
                            ${i.price} <span className="planMonth">/month</span></h3>
                        <p className="planPara">
                            {i.planDescription}
                        </p>
                        {i.features.map((k => {
                            return(
                                <div className="mb-15 flex-simple-center planBox-option" key={k.id}>
                                    <img className="planBox-tick" src={k.img} alt="img" />
                                    <p className="planBox-text ml-10">
                                        {k.feature}
                                    </p>
                                </div>
                            )
                        }))}
                        <div className="flex-center-align flex-column">
                            <button className="planBox-btn br-5 mt-20">Try Now</button>
                        </div>
                </div>
            </div>
            )))}
        </Fragment>
    )
}

export default PlanBox;