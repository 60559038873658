import React from 'react';
import { Fragment } from 'react';

export default function Payment9(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                09.
            </span>
            Frequently Asked Questions (FAQs) 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ol>
                <li>
                    <b>How Long Does It Take to Process Payments?</b>
                    <p className="paragraph p-adjustment text-left">
                    Payment processing times can vary depending on the payment method and the financial institutions involved. Generally, payments made through methods like credit/debit cards, PayPal, Stripe or online banking tend to be processed quickly, often within minutes. However, it's important to note that bank transfers or other traditional methods may take a few business days to complete. To get the most accurate information about payment processing times, refer to the specific payment method's documentation.</p>
                    
                    <p className="paragraph p-adjustment text-left">
                    It is also important to know that your initial transfer may take longer depending on your industry risk level and country of operation. Please refer to Stripe <a href='https://docs.stripe.com/payouts'>support page</a> for more information.
                    </p>
                </li>
                <li>
                    <b>How to Withdraw Payments?</b>
                    <p className="paragraph p-adjustment text-left">
                    To withdraw payments received through Invoice Point, you'll typically need to link a bank account or payment service to your Invoice Point account. This process allows you to transfer funds from your Invoice Point account to your chosen financial institution. The steps may vary depending on your location and the payment method you've used.
                    </p>
                </li>
                <li>
                    <b>What Is the Processing Fee?</b>
                    <p className="paragraph p-adjustment text-left">
                    The processing fee associated with payments made through Invoice Point may vary depending on the payment method you choose and your geographic location. Keep in mind that some payment methods may impose their own fees, which will be separate from Invoice Point's processing fees.
                    </p>
                </li>
                <li>
                    <b>How Can I Link My Stripe Account to the App?</b>
                    <p className="paragraph p-adjustment text-left">
                    To link your Stripe account to Invoice Point, follow these steps:
                    <ol>
                        <li>
                        Log in to your Invoice Point account
                        </li>
                        <li>
                        Navigate to the payment settings under general app setting section.
                        </li>
                        <li>
                        Select "Setup Account."
                        </li>
                        <li>
                        Follow the on-screen instructions to connect your Stripe account securely. You may need to provide relevant Stripe API keys or credentials.
                        </li>
                        <li>
                        Once connected, your Invoice Point account will be integrated with Stripe, allowing you to accept payments seamlessly.
                        </li>
                    </ol>
                    </p>
                </li>
                <li>
                    <b>How Do I Send Invoices to My Clients?</b>
                    <p className="paragraph p-adjustment text-left">
                    To send invoices to your clients through Invoice Point:
                    <ol>
                        <li>
                        Log in to your Invoice Point account.
                        </li>
                        <li>
                        Access the invoice section.
                        </li>
                        <li>
                        Select "Add Invoice" button
                        </li>
                        <li>
                        Fill in the necessary details, including client information, invoice items, and payment terms.
                        </li>
                        <li>
                        Review and finalize the invoice.
                        </li>
                        <li>
                        Choose your preferred delivery method (e.g., WhatsApp, email, direct messaging).
                        </li>
                        <li>
                        Send the invoice to your client.
                        </li>
                        <li>
                        You can track the status of the invoice within your Invoice Point dashboard to monitor when it's viewed and paid.
                        </li>
                    </ol>
                    </p>
                </li>
                <li>
                    <b>How Can I Track the Status of My Invoices?</b>
                    <p className="paragraph p-adjustment text-left">
                    Tracking the status of your invoices is simple with Invoice Point:
                    <ol>
                        <li>
                        Log in to your Invoice Point account.
                        </li>
                        <li>
                        Access the invoice section.
                        </li>
                        <li>
                        Select "Add Invoice" button
                        </li>
                        <li>
                        Review the list of invoices to see their respective statuses.
                        </li>
                        <li>
                        Invoice statuses typically include "Pending," and "Marked as Paid."
                        </li>
                        <li>
                        If your App notification is active then you can also receive read status notifications of your invoices.
                        </li>
                    </ol>
                    </p>
                </li>
                <li>
                    <b>What Happens if a Payment Fails or Is Disputed?</b>
                    <p className="paragraph p-adjustment text-left">
                    If a payment made through Invoice Point fails or is disputed, our system will automatically handle the situation according to predefined rules. For failed payments, you'll typically receive a notification, and you may need to follow up with the client to resolve the issue or request an alternative payment method. In the case of a dispute, we recommend carefully reviewing the dispute details and providing any necessary documentation to resolve the matter amicably. If you encounter challenges with payment issues, you can always contact our support team for guidance and assistance.
                    </p>
                </li>
                <li>
                    <b>How Do I Contact Customer Support for Payment-Related Issues?</b>
                    <p className="paragraph p-adjustment text-left">
                    If you encounter payment-related issues or require assistance, our customer support team is readily available to help you. You can reach our support team by navigating to the "Contact Us" or "Support" section within your Invoice Point account. Alternatively, you may find contact details, including email addresses and phone numbers, on our official website. Our dedicated support representatives are here to address your concerns and provide prompt assistance with payment-related matters.
                    </p>
                </li>
            </ol>
            </p>
        </div>
        </Fragment>
    )
}