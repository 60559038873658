import React from 'react';
import { Fragment } from 'react';

export default function Cookie8(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                06.
            </span>
            Contact us 
            </h4>
            <p className="paragraph p-adjustment text-left">
            If you have any questions about our use of cookies or other technologies, please email us at <a href='mailto: support@invoicepoint.app'>support@invoicepoint.app</a>.
            </p>
        </div>
        </Fragment>
    )
}