import React from 'react';
import { Fragment } from 'react';

export default function Cookie1(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold ">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                01.
            </span>
            Understanding Cookies
            </h4>
            <p className="paragraph p-adjustment text-left">
            Cookies are small text files stored on your device (computer, tablet, mobile) when you visit websites. They are widely used to ensure websites work efficiently, provide information to site owners, and enhance user experience.
            </p>
        </div>
        </Fragment>
    )
}