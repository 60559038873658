import React from 'react';

export default function Question14(){
    return(
        <React.Fragment>
            <div className="question mt-40">
                <h4 id='q14' className="h-4 h-bold">
                    <span style={{opacity:"0.4", marginRight:"10px"}}>
                        15.
                    </span>
                    Ads and Remarketing Services
                </h4>
                <p className="paragraph p-adjustment text-left">
                Invoice Point hereby declares and acknowledges that the Application employs advertising as a component of its functionality. Users should be cognizant that the Application may display advertisements in various formats as part of the user experience. Invoice Point shall ensure that these advertisements are in compliance with applicable laws and regulations. Users are encouraged to review the privacy policy for further information regarding data collection and targeting practices associated with these advertisements.
                </p>
                <p className="paragraph p-adjustment text-left">
                We may use remarketing services. In digital marketing, remarketing (or retargeting) is the practice of serving ads across the internet to people who have already visited your website. It allows your company to seem like they're “following” people around the internet by serving ads on the websites and platforms they use most.
                </p>
            </div>
            <div className="question mt-40">
                <h4 id='q15' className="h-4 h-bold">
                    <span style={{opacity:"0.4", marginRight:"10px"}}>
                        16.
                    </span>
                    Payment Details
                </h4>
                <p className="paragraph p-adjustment text-left">
                We only use Third-Party Payment Services Providers and do not process any payments on our own. In respect to any credit card or other payment processing details you have provided us, we commit that this confidential information will be stored in the most secure manner possible. You agree to be bound by any privacy policies or other policies of Third-Party Payment Services Providers.
                </p>
                <p className="paragraph p-adjustment text-left">
                If you are located outside of SEPA-supported countries, please be aware that we may transfer your personal information to countries that may not have the same data protection laws as your jurisdiction. We will take appropriate measures to protect your personal information when it is transferred internationally.
                </p>
            </div>
            <div className="question mt-40">
                <h4 id='q16' className="h-4 h-bold">
                    <span style={{opacity:"0.4", marginRight:"10px"}}>
                        17.
                    </span>
                    Children’s Privacy
                </h4>
                <p className="paragraph p-adjustment text-left">
                We do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and If you are aware that your child has provided us with personal data or information, please contact us. If we become aware that we have collected personal data or information from anyone under the age of 18 without verification of parental consent, we will take steps to remove that information from our servers.
                </p>
            </div>
            <div className="question mt-40">
                <h4 id='q17' className="h-4 h-bold">
                    <span style={{opacity:"0.4", marginRight:"10px"}}>
                        18.
                    </span>
                    Third-Party Services
                </h4>
                <p className="paragraph p-adjustment text-left">
                We may display, include or make available Third-Party Services.
                </p>
                <p className="paragraph p-adjustment text-left">
                You acknowledge and agree that Invoice Point shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Invoice Point does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.
                </p>
                <p className="paragraph p-adjustment text-left">
                Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
                </p>
            </div>
            <div className="question mt-40">
                <h4 id='q18' className="h-4 h-bold">
                    <span style={{opacity:"0.4", marginRight:"10px"}}>
                        19.
                    </span>
                    Tracking Technologies
                </h4>
                <p className="paragraph p-adjustment text-left">
                We automatically collect certain types of usage information when you access or use the Services, including Cookies and similar tracking technologies, such as pixels and web beacons.
                </p>
                <p className="paragraph p-adjustment text-left">
                We use Cookies to enhance the performance and functionality of our App and Website but are non-essential to their use. However, without these Cookies, certain functionality may become unavailable or you would be required to enter your login details every time you visit the App or Website as we would not be able to remember that you had logged in previously.
                </p>
            </div>
            <div className="question mt-40">
                <h4 id='q19' className="h-4 h-bold">
                    <span style={{opacity:"0.4", marginRight:"10px"}}>
                        20.
                    </span>
                    Changes to Our Privacy Policy
                </h4>
                <p className="paragraph p-adjustment text-left">
                Invoice Point reserves the right, at its sole discretion, to modify or replace the Privacy Policy at any time. We may change our Services and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Services and policies. Unless otherwise required by law, we will notify you (for example, through our Services) before we make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the App, the Website or any of the Services, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account.
                </p>
            </div>
        </React.Fragment>
    )
}