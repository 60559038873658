import React from 'react';
import { Fragment } from 'react';

export default function Terms31(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                31.
            </span>
            Typographical Errors 
            </h4>
            <p className="paragraph p-adjustment text-left">
            In the event a product and/or service is listed at an incorrect price or with incorrect information due to typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or another payment account in the amount of the charge in accord with the Third-Party Payment Service Provider. 
            </p> 
        </div>
        </Fragment>
    )
}