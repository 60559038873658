import React from 'react';

export default function Question1(){
    return(
        <div className="question mt-40">
            <h4 id='q1' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    01.
                </span>
                Acceptance of This Privacy Policy
            </h4>
            <p className="paragraph p-adjustment text-left">
            By accessing or using our Services on our App or our Website, you signify acceptance to the terms of this Privacy Policy. Where we require your consent to process your personal information, we will ask for your consent to the collection, use, and disclosure of your personal information as described further below. We reserve the right to provide supplementary disclosures or information concerning the data processing practices of specific Services, which may be presented without prior notice. These notices may supplement or clarify our privacy practices or may provide you with additional choices about how we process your data.
            </p>
            <p className="paragraph p-adjustment text-left">
            If you do not agree with or you are not comfortable with any aspect of this Privacy Policy, you should immediately discontinue access or use of our Services and/or our Website.
            </p>
            <ol>
                <li>
                    <b>Accessing Our App and Its Features - User Guide</b>
                    <p className="paragraph p-adjustment">
                    Access to certain restricted sections of the Invoice Point Application is available to registered users, and can be accomplished through the following five-step process:
                    </p>
                    <p className="paragraph p-adjustment">
                        <b>
                        Step 1: Account Setup
                        </b>
                        <br/>
                        Start by creating an Invoice Point account. We'll ask for some necessary details during the registration process.
                    </p>
                    <p className="paragraph p-adjustment">
                        <b>
                        Step 2: Generate Invoices
                        </b>
                        <br/>
                        Once you’ve got your account set up, it’s time to create invoices. Pick a template, add your logo, customize the invoice to your liking, add client info, and items. Voilà, your invoice is good to go!
                    </p>
                    <p className="paragraph p-adjustment">
                        <b>
                        Step 3: Customize Your Invoices
                        </b>
                        <br/>
                        Want to give your invoices a personal touch? Head to the settings where you can customize layouts, add your company logo.
                    </p>
                    <p className="paragraph p-adjustment">
                        <b>
                        Step 4: Sending Invoices and Estimates
                        </b>
                        <br/>
                        We make it super easy to send invoices. You can shoot them off through text, email, or even via WhatsApp and Facebook Messenger. Sending estimates is just as simple – select 'Quotes' from the menu, fill in client details, add items, and your estimate is ready to roll!
                    </p>
                    <p className="paragraph p-adjustment">
                        <b>
                        Step 5: Access Business Reports
                        </b>
                        <br/>
                        Curious about your business stats? Go to the 'Reports' screen that you can access from the 'More' screen. You'll find a multiple useful type of reports there, like outstanding balances, annual and monthly sales figures, and much more.
                    </p>
                </li>
            </ol>
        </div>
    )
}