import React from 'react';
import { Fragment } from 'react';

export default function Terms33(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                33.
            </span>
            Miscellaneous 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point will be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you.  
            </p> 
            <p className="paragraph p-adjustment text-left">
            Invoice Point operates and controls the Invoice Point Services from its offices in the Netherlands and the Germany. The Services are not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. Accordingly, those persons who choose to access the Invoice Point Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms (which include and incorporate the Invoice Point Privacy Policy) contain the entire understanding, and supersede all prior understandings, between you and Invoice Point concerning its subject matter, and cannot be changed or modified by you. The section headings used in these Terms are for convenience only and will not be given any legal import.
            </p>
        </div>
        </Fragment>
    )
}