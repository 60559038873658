import React from 'react';
import { Fragment } from 'react';

export default function Terms9(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                09.
            </span>
            Your Suggestions  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Any Suggestions provided by you to Invoice Point with respect to the App and/or the Website shall remain the sole and exclusive property of Invoice Point. Invoice Point shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.
            </p>
            <p className="paragraph p-adjustment text-left">
            In the event that you submit or post any ideas, creative suggestions, designs, photographs, information, advertisements, data, or proposals, including ideas for new or improved products, services, features, technologies, or promotions (Suggestions), you expressly agree that such Suggestions will automatically be treated as non-confidential and non-proprietary and will become the sole property of Invoice Point without any compensation or credit to you whatsoever. Invoice Point and its affiliates shall have no obligations with respect to such submissions or posts and may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.
            </p>
        </div>
        </Fragment>
    )
}