import React from 'react';

export default function Question7(){
    return(
        <div className="question mt-40">
            <h4 id='q7' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    08.
                </span>
                Sale of Business
            </h4>
            <p className="paragraph p-adjustment text-left">
            We reserve the right to transfer data and information to a third party in the event of a sale, merger, or other transfer of all or substantially all of the assets of Invoice Point or any of its Corporate Affiliates, or that portion of Invoice Point or any of its Corporate Affiliates to which the Services relate, or in the event that we discontinue our business or file a petition, or have filed against us, a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.
            </p>
        </div>
    )
}