import React from 'react';
import { Fragment } from 'react';

export default function Terms14(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                13.
            </span>
            Modifications to Our App and Website  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point is always trying to innovate and expand the Services to provide the best possible experience for you. Therefore you may see changes without prior warning. Flexibility is needed so we can make changes, impose or change limits where and when necessary, and sometimes terminate features to wholly improve our App, our Website or the Services we provide on it.

            </p>
            <p className="paragraph p-adjustment text-left">
            Invoice Point reserves the right to modify, suspend or discontinue, temporarily or permanently, the App and the Website or any service to which it connects, with or without notice and without liability to you. 
            </p>
        </div>
        </Fragment>
    )
}