import React from 'react';
import { Fragment } from 'react';

export default function Payment6(){
    return(
        <Fragment>
            <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                06.
            </span>
            Processing Fee 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point shall deduct a processing fee equivalent to 1.5% of the total transaction amount for each transaction conducted through the Invoice Point platform (the "Processing Fee"). The Processing Fee shall be automatically deducted at the time of the transaction, and the net transaction amount, after deducting the Processing Fee, shall be disbursed or credited to the Counterparty accordingly.
            </p>
            <p className="paragraph p-adjustment text-left">
            When using Invoice Point, remember that third-party platforms may charge their own processing fees for transactions made through their services and depending on the payment method.
            </p>
        </div>
        </Fragment>
    )
}