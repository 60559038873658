import React from 'react';
import { Fragment } from 'react';

export default function Terms22(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                22.
            </span>
            Force Majeure  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point shall not be liable or responsible to you for not performing any act or fulfilling any duty or obligation connected to these Terms, when and to the extent that such action is caused by any occurrence that is not within its control, called Force Majeure events, which are defined in Article 2 above.  
            </p>
            <p className="paragraph p-adjustment text-left">
            In case of Force Majeure we shall use reasonable efforts consistent with accepted practice to notify you right away of such an event and try to resume performance as soon as reasonably possible under the circumstances. We will also try to minimise the effects of such Force Majeure events.
            </p>
        </div>
        </Fragment>
    )
}