import React from 'react';
import { Fragment } from 'react';

export default function Terms32(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                32.
            </span>
            Indemnification 
            </h4>
            <p className="paragraph p-adjustment text-left">
            You agree to hold harmless and indemnify, individually or collectively, Invoice Point as well as our officers, agents, employees, advertisers, licensors, suppliers or partners from and against any losses, liabilities, claims, demands, damages, expenses or costs arising out of or related to your violation, misconduct or infraction of any rights of another, such as intellectual property rights or privacy rights, or your violation of the law. You agree that we will have control of the defence or settlement of any such Claims. By agreeing to our Terms you also acknowledge to promptly notify Invoice Point of any third-party Claims, cooperate with Invoice Point Parties in defending such Claims and pay all fees, costs and expenses associated with defending such Claims, including, but not limited to, reasonable attorneys’ fees, costs, penalties, interest and disbursements.  
            </p> 
        </div>
        </Fragment>
    )
}