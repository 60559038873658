import React from 'react';
import { Fragment } from 'react';

export default function Cookie7(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                07.
            </span>
            Policy Updates
            </h4>
            <p className="paragraph p-adjustment text-left">
            This Cookie Policy may be updated to reflect changes in legislation or our data use practices. We encourage you to review this policy periodically.
            </p>
        </div>
        </Fragment>
    )
}