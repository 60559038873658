import React from 'react';
import { Fragment } from 'react';

export default function Terms5(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                05.
            </span>
                Account 
            </h4>
            <ol type='1'>
                <li><b>Legibility</b>
                    <p className="paragraph p-adjustment text-left">
                        By using the App, the Website or any of our Services you hereby confirm that you are at least 18 years old and legally competent to enter into and to contractually abide by the terms, conditions, representations and warranties set forth in these Terms.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        You are solely responsible for checking the laws regarding the use of the App, the Website or any of our Services in the jurisdiction in which you are located to ensure that your registration with Invoice Point is legal. We will not be held liable or responsible for any Users using the App, the Website or the Services unlawfully. In any doubt you shall cease the use of the App, the Website or the Services immediately and check the relevant applicable laws. Therefore eligibility is voided where prohibited by law.
                    </p>
                </li>
                <li><b>Account Information and Use</b>
                    <p className="paragraph p-adjustment text-left">
                        In order to access the Services in the App or on the Website, you must register with Invoice Point by completing the registration form and creating a user Account provided via the App or the Website. You agree to:
                    </p>
                    <ul>
                    <li className="paragraph p-adjustment text-left">
                        provide accurate, current, and complete Registration Data;
                    </li>
                    <li className="paragraph p-adjustment text-left">
                        maintain the security of your Account password;
                    </li>
                    <li className="paragraph p-adjustment text-left">
                        maintain and promptly update the Registration Data, and any other information you provide to us, to keep it accurate, current, and complete; and 
                    </li>
                    <li className="paragraph p-adjustment text-left">
                        accept all risks of unauthorized access to the Registration Data and any other information you provide to Invoice Point. 
                    </li>
                    </ul>
                    <p className="paragraph p-adjustment text-left">
                        We may, at any time, and in its sole discretion, deny you the option to open an Account, limit the Account that you may establish and maintain, or suspend any operation pending our review of any information submitted by you.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        You are responsible for safeguarding the passwords you use to access the Services and agree to be fully responsible for activities or transactions that relate to your Account or password. You must notify us immediately if you learn of an unauthorised use of your Account or password.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        <b>Misuse.</b>  The creation or use of an Account without obtaining our prior express permission will result in the immediate suspension of any such Account, as well as all use of Services. Any attempt to do so or to assist others (former registered users or otherwise), or the distribution of instructions, software or tools for that purpose, will result in the Accounts of such Users being terminated, without derogating from any other remedy to which we may be entitled for such a violation and Invoice Point may take further actions against you.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        You are required to notify us immediately of any unauthorised use of your Account or password, suspected compromise of your login information, or any other breach of security, by email addressed to the address at the bottom of this document.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        We may terminate the Account of anyone who does not comply with our potential verification or security requests, or otherwise violates the foregoing rules, and such individual may be held liable for losses incurred by us or by any third party due to their non-compliance and/or violation of rules. 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        <b>Responsibility for Third Party Acts.</b>  Your Account is for your personal use only, and not for the use or access by any third party. In any event, you are fully responsible for all acts or omissions of any third party accessing and/or using your Account.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        You agree that you will not use the Services to perform criminal activity of any sort, including but not limited to, money laundering, illegal gambling operations, terrorist financing, or malicious hacking. Should Invoice Point determine in its sole discretion that the activity on your Account is suspicious or related to any prohibited activity or illegitimate operation, Invoice Point may cancel or suspend your Account.
                    </p>
                </li>
                <li>
                    <b>Communication</b>
                    <p className="paragraph p-adjustment text-left">
                    By creating an Account you consent to receive electronic communications from us via email or by posting notices on our App, Website, etc. This may include notifications regarding your Account (e.g. password changes). You agree that the notices, agreements and other electronic communications we send to you will meet any legal requirements regarding communications, including for example, that such communications are to be in writing. We encourage you to either save an electronic copy or print a paper copy of electronic communications you receive from us in order to maintain copies of our electronic communications.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    You may also receive promotional communications from us via email, e.g. special offers, surveys and other information we believe might interest you. You have the possibility to opt out of such emails at any time by following the instructions regarding the unsubscribing provided therein. 
                    </p>
                </li>
            </ol>
        </div>
        </Fragment>
    )
}