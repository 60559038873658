import React from 'react';
import { Fragment } from 'react';

export default function Terms13(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                13.
            </span>
            Changes To Our Terms  
            </h4>
            <p className="paragraph p-adjustment text-left">
            You acknowledge and agree that we may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to Users generally at our sole discretion, without prior notice to you. You may stop using the Services at any time. You do not need to specifically inform when you stop using the Services. You acknowledge and agree that if you disable access to your Account, you may be prevented from accessing the Services, your Account details, or any files or other materials which are contained in your Account. If we decide to change our Terms, we will post those changes on this page, and/or update the Terms modification date above.
            </p>
            <p className="paragraph p-adjustment text-left">
            You can suspend or terminate these Terms at any time if you cancel your Account and stop accessing and using our Services. If you terminate these Terms you are not entitled to any refunds.
            </p>
        </div>
        </Fragment>
    )
}