import React from 'react';
import { Fragment } from 'react';

export default function Cookie2(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                02.
            </span>
            Our Use of Cookies 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Our Website uses both first-party cookies (set by InvoicePoint) and third-party cookies (set by other companies). These are used to make our Website function correctly, analyze how our Website is used, and improve your user experience. Third-party cookies may also support our advertising efforts to offer you tailored content.
            </p>
        </div>
        </Fragment>
    )
}