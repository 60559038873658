import React from 'react';

export default function Question5(){
    return(
        <div className="question mt-40">
            <h4 id='q5' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    05.
                </span>
                How Do We Protect Your Information?
            </h4>
            <ol>
                <li>
                <b>General</b>
                <p className="paragraph p-adjustment">
                We implement a variety of security measures to maintain the safety of your personal data when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database for the Third-Party Payment Services Providers only to be accessible by those authorized with special access rights to such systems, and are required to keep the personal data confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Invoice Point or guarantee that your information on the Services may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.
                </p>
                </li>
                <li>
                <b>Will my information be transferred to other countries and where will it be stored?</b>
                <p className="paragraph p-adjustment">
                Invoice Point is incorporated in the Netherlands. Information collected via our App and/or the Website, through direct interactions with you, or from the use of our help services, will be transferred to and digitally stored on third party servers provided by Amazon, specifically AWS EU-Central servers, located in Frankfurt Germany and not transferred elsewhere.
                </p>
                </li>
                <li>
                <b>Is the information collected through the Invoice Point Service secure?
</b>
                <p className="paragraph p-adjustment">
                We take precautions to protect the security of your personal data. We have physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain data security, and correctly use your personal data. However, neither people nor security systems are foolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal data, we cannot guarantee its absolute security. If applicable law imposes any non-disclosable duty to protect your personal data, you agree that intentional misconduct will be the standards used to measure our compliance with that duty.
                </p>
                </li>
                <li>
                <b>Can I update or correct my personal data?</b>
                <p className="paragraph p-adjustment">
                The rights you have to request updates or corrections to the data Invoice Point collects depend on your relationship with Invoice Point. Personnel may update or correct their data as detailed in our internal company employment policies. Users have the right to request the restriction of certain uses and disclosures of personally identifiable information as follows. You can contact us in order to (1) update or correct your personally identifiable information, (2) change your preferences with respect to communications and other information you receive from us, or (3) delete the personally identifiable information maintained about you on our systems (subject to the following paragraph), by canceling your Account. Such updates, corrections, changes, and deletions will have no effect on other information that we maintain, or information that we have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change, or deletion. To protect your privacy and security, we may take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and Account information at all times.
                </p>
                <p className="paragraph p-adjustment"> 
                You should be aware that it is not technologically possible to remove each and every record of the data you have provided to us from our system. The need to back up our systems to protect data from inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate. Promptly after receiving your request, all personal data stored in databases we actively use, and other readily searchable media will be updated, corrected, changed, or deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.
                </p>
                <p className="paragraph p-adjustment">
                If you are a receiving party and wish to update, delete, or receive any information we have about you, you may do so by contacting the organization of which you are a customer.
                </p>
                </li>
            </ol>
        </div>
    )
}