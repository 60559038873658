import React from 'react';
import { Fragment } from 'react';

export default function Cookie5(){
    return(
        <Fragment>
            <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                05.
            </span>
            Your Rights under GDPR 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Under GDPR, you have the right to access, correct, delete, and restrict the processing of your personal data. You also have the right to object to the processing of your personal data, including profiling and direct marketing. For any concerns or requests related to your data rights, please contact us directly.
            </p>
        </div>
        </Fragment>
    )
}