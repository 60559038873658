import React from 'react';

export default function Question9(){
    return(
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    10.
                </span>
                Governing Law
            </h4>
            <p className="paragraph p-adjustment text-left">
            This Privacy Policy is governed by the laws of the Netherlands without regard to its conflict of laws provision. You consent to the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or in connection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or the Swiss-US framework.
            </p>
            <p className="paragraph p-adjustment text-left">
            The laws of the Netherlands, excluding its conflicts of law rules, shall govern this Agreement and your use of the App and/or the Website. Your use of the App and/or the Website may also be subject to other local, state, national, or international laws.
            </p>
            <p className="paragraph p-adjustment text-left">
            By using Invoice Point Services or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, you may not engage with our App and/or Website, or use our Services. Continued use of the App, the Website, direct engagement with us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your personal data will mean that you accept those changes.
            </p>
        </div>
    )
}