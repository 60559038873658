import React from 'react';
import { Fragment } from 'react';

export default function Payment11(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                11.
            </span>
            CONCLUSION 
            </h4>
            <p className="paragraph p-adjustment text-left">
            This comprehensive guide equips you with the knowledge and tools needed to make the most of Invoice Point's Payment System. We encourage you to explore the full spectrum of features offered by our payment system to optimize your financial management capabilities.
            </p>
            <p className="paragraph p-adjustment text-left">
            Thank you for choosing Invoice Point's Payment System and entrusting us with your business. It's our pleasure to assist you in achieving financial control, efficiency, and client satisfaction.
            </p>
        </div>
        </Fragment>
    )
}