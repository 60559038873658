import React from 'react';
import Cookie1 from './c01';
import Cookie2 from './c02';
import Cookie3 from './c03';
import Cookie4 from './c04';
import Cookie5 from './c05';
import Cookie6 from './c06';
import Cookie7 from './c07';
import Cookie8 from './c08';

export default function CookiePolicy(){
    return(
        <section className="sp-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="h-1 h-bold text-left">
                        Cookie Policy for InvoicePoint
                        </h1>
                        <p className="paragraph p-adjustment text-left">
                        Last Updated on 2024-02-09
                        </p>
                        <p className="paragraph p-adjustment text-left">
                        Welcome to InvoicePoint ("we", "us", or "our"). Protecting your private information is our priority, and we are committed to ensuring that your rights to privacy are respected and protected. Our use of cookies and similar technologies is conducted with respect and transparency.
                        </p>
                        <p className="paragraph p-adjustment text-left">
                        When you visit our website <a href='/'>https://invoicepoint.app/</a> (the "Website"), or use any of our services (the "Services"), you trust us with your personal information. We take your privacy very seriously. In this cookie policy, we aim to explain clearly what information we collect, how we use it, and what rights you have concerning it. We strongly advise you to read this policy carefully.
                        </p>
                        <Cookie1 />
                        <Cookie2 />
                        <Cookie3 />
                        <Cookie4 />
                        <Cookie5 />
                        <Cookie6 />
                        <Cookie7 />
                        <Cookie8 />
                    </div>
                </div>
            </div>
        </section>
    )
}