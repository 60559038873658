import React from 'react';

export default function Question8(){
    return(
        <div className="question mt-40">
            <h4 id='q8' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    09.
                </span>
                Affiliates
            </h4>
            <p className="paragraph p-adjustment text-left">
            We may disclose information (including personal data) about you to our Corporate Affiliates. Any information relating to you that we provide to our Corporate Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.
            </p>
        </div>
    )
}