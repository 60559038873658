import React from 'react';
import { Fragment } from 'react';

export default function Cookie6(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                06.
            </span>
            Managing and Disabling Cookies 
            </h4>
            <p className="paragraph p-adjustment text-left">
            You have control over cookies and can manage their use through your browser settings. For more detailed information on how to manage or disable cookies, refer to your browser’s help documentation.
            </p>
        </div>
        </Fragment>
    )
}