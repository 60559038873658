import React from 'react';
import { Fragment } from 'react';

export default function Payment8(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                08.
            </span>
            Security and Privacy
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ol>
                <li>
                    <b>Data Protection:</b>
                    <p className="paragraph p-adjustment text-left">
                    Your payment data is handled with utmost care, featuring state-of-the-art encryption and tokenization. Our stringent security measures ensure the safeguarding of your financial information.
                    </p>
                </li>
                <li>
                    <b>Privacy Policy:</b>
                    <p className="paragraph p-adjustment text-left">
                    Delve into the intricacies of our privacy policy to ensure transparency regarding data collection, usage, and retention by clicking this link.
                    </p>
                </li>
            </ol>
            </p>
        </div>
        </Fragment>
    )
}