import React from 'react';
import { Fragment } from 'react';

export default function Terms28(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                28.
            </span>
            AGREEMENT TO ARBITRATE, DISPUTE RESOLUTION 
            </h4>
            <p className="paragraph p-adjustment text-left">
            This section applies to any dispute between you and Invoice Point. The Term “Dispute” means any disputes, actions, claims, differences or other controversies between you and Invoice Point arising under and in connection with the Services or these Terms, whether in contract, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible meaning allowable under law.
            </p>
            <ol>
            <li>
            <b>Notice of Dispute</b>
            <p className="paragraph p-adjustment text-left">
            In the event of a Dispute, you or Invoice Point must give the other a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to: <a href='mailto: support@invoicepoint.app'>support@invoicepoint.app</a>. 
            </p>
            <p className="paragraph p-adjustment text-left">
            Invoice Point will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and Invoice Point will attempt to resolve any Dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or Invoice Point may commence arbitration.
            </p>
            </li>
            <li>
            <b>Binding Arbitration</b>
            <p className="paragraph p-adjustment text-left">
            If you and Invoice Point do not resolve any dispute by informal negotiation, any other effort to resolve the dispute will be conducted exclusively by binding arbitration as described in this section. You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the commercial arbitration rules of the The Dutch Arbitration Act and The Dutch Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party. 
            </p>
            <p className="paragraph p-adjustment text-left">
            Both you and Invoice Point agree to keep confidential all matters relating to the dispute, including related court proceedings, if any, to the greatest extent practicable. For purposes of this Article, references to you and Invoice Point also include respective subsidiaries, affiliates, agents, employees, predecessors, successors, and assigns.
            </p>
            </li>
            </ol> 
        </div>
        </Fragment>
    )
}