import React from 'react';

export default function Question10(){
    return(
        <div className="question mt-40">
            <h4 id='q10' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    11.
                </span>
                What Are Your Rights
            </h4>
            <p className="paragraph p-adjustment text-left">
            As our User you have certain rights regarding the processing of your personal information. The extent of these rights depends on where you are located. If you reside in the European Union (EU) or European Economic Area (EEA), please refer to sections 10.1. and all relevant subsections, on the other hand, if you reside outside the EU refer to section 10.2., and section 10.3. if you are a resident of California. For any further questions regarding your rights, please feel free to contact us at any time via email disclosed below.
            </p>
            <ol>
                <li>
                <b className='h-bold'>Information about General Data Protection Regulation (GDPR)</b>
                <p className="paragraph p-adjustment text-left">
                We may be collecting and using information from you if you are from the EU or the EEA, and in this section of our Privacy Policy, we are going to explain exactly how and why this data is collected, and how we maintain this data under protection from being replicated or used in the wrong way.
                <ul>
                    <li>
                    GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by companies and enhances the control the EU residents have over their personal data.
                    </li>
                    <li>
                    The GDPR is relevant to any globally operating company and not just the EU-based businesses and EU residents. Our customers’ data is important irrespective of where they are located, which is why we have implemented GDPR controls as our baseline standard for all our operations worldwide.
                    </li>
                </ul>
                </p>
                </li>
                <li>
                <b className='h-bold'>What is personal data?</b>
                <p className="paragraph p-adjustment text-left">
                Personal data is any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information that could be used on its own, or in combination with other pieces of information, to identify a person. Personal data extends beyond a person’s name or email address. Some examples include financial information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity.
                <ol type='a'>
                    <li>
                    <b className='h-bold'>The Data Protection Principles include requirements such as:</b>
                    <ul>
                        <li>
                        Personal data collected must be processed in a fair, legal, and transparent way and should only be used in a way that a person would reasonably expect.
                        </li>
                        <li>
                        Personal data should only be collected to fulfill a specific purpose and it should only be used for that purpose. Organizations must specify why they need the personal data when they collect it.
                        </li>
                        <li>
                        Personal data should be held no longer than necessary to fulfill its purpose.
                        </li>
                        <li>
                        People covered by the GDPR have the right to access their own personal data. They can also request a copy of their data, and that their data be updated, deleted, restricted, or moved to another organization.
                        </li>
                        </ul>
                    </li>
                    <li>
                    <b className='h-bold'>Why is GDPR important?</b>
                    <p className="paragraph p-adjustment text-left">
                    GDPR adds some new requirements regarding how companies should protect individuals' personal data that they collect and process. It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breach. Beyond these facts, it's simply the right thing to do. At Invoice Point we strongly believe that your data privacy is very important and we already have solid security and privacy practices in place that go beyond the requirements of this new regulation.
                    </p>
                    </li>
                    <li>
                    <b className='h-bold'>Individual Data Subject's Rights - Data Access, Portability and Deletion
</b>                <p className="paragraph p-adjustment text-left">
                    If you are located within the EU or EEA, you have the following rights, guaranteed by the GDPR:
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right to be informed</b> - you have the right to concise, transparent, intelligible and easily accessible information regarding the processing of your personal data. 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right of access</b> - you have the right to access the personal data we have about you. You can ask us for confirmation as to whether your personal data is being used and in what way we use it (e.g. the purposes of the processing, the recipients). A copy of your personal data shall also be provided to you at your request. 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right to rectification (updating your information)</b> - on your request we shall, without undue delay, ensure that inaccurate personal data is updated. Taking into account the purposes of the processing, you also have the right to have incomplete personal data completed.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right to erasure (deleting your information)</b> - when certain specific criteria are met (Article 17 GDPR), you have the right to ask us to delete any personal data we hold about you without undue delay. 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right to restriction of processing</b> - under certain circumstances (Article 18 GDPR), you can request that we restrict the way in which we process your personal data. 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right to data portability</b> - you have the right to receive the personal data that we hold about you in a structured, commonly used, machine-readable format. You may also transfer this data to a third party provider of services. Note that this right only applies when we use your data on the basis of your consent or performance of a contract and our use of your information is carried out by automated means.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Right to object</b> - you have the right to object at any time to our processing of your personal data if you have a valid reason. Unless there are compelling legitimate grounds for further processing, we shall stop processing your personal data. This right also enables you to require from us that we stop using your personal data for direct marketing purposes.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b className='h-bold'>Complaints</b> - you may make a complaint to the relevant data protection regulator under the conditions from GDPR.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Your privacy is very important to us so if you have any question regarding your rights or the circumstances in which these rights are applicable, please contact us. You can also read more detailed descriptions of each right in Chapter III of the GDPR (<a href='https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&'>https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&</a>). 
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Legal bases for processing information of EU residents - if you are an EU resident, we will only process your personal data and information when one (or more) of the following requirements is met:
                    </p>
                    <ul>
                        <li>
                        When we have your consent to do so. If consent is the legal basis for the processing of your personal data, you may withdraw your consent at any time.
                        </li>
                        <li>
                        When processing is necessary for the performance of the contract to which you are a party to (e.g. for providing the services you requested).
                        </li>
                        <li>
                        To comply with our legal obligations.
                        </li>
                        <li>
                        When it is necessary to protect your vital interests or those of other individuals.
                        </li>
                        <li>
                        When processing is necessary for the performance of a task carried out in the public interest.
                        </li>
                        <li>
                        When we (or a third party) have a legitimate interest in processing your personal data.
                        </li>
                    </ul>
                    <p className="paragraph p-adjustment text-left">
                    We are committed to helping our customers meet the data subject rights requirements of GDPR. Invoice Point processes or stores all personal data in fully vetted, GDPR compliant vendors. We do store all conversation and personal data for up to 6 years unless your account is deleted. In which case, we dispose of all data in accordance with our Terms and Conditions and Privacy Policy, but we will not hold it longer than 60 days. We are aware that if you are working with EU customers, you need to be able to provide them with the ability to access, update, retrieve and remove personal data. We've been set up as self-service from the start and have always given you access to your data and your customers’ data. Our customer support team is here for you to answer any questions you might have about working with the API.
                    </p>
                    </li>
                    </ol>
                </p>
                </li>
                <li>
                <b>Rights of the individuals residing outside the EU or EEA </b>
                <p className="paragraph p-adjustment text-left">
                If you are a non-EU/EEA User of our Services your personal data may be processed for the purposes described in this Privacy Policy in accordance with the rights described above. However, we reserve the right to process your data by the standards of your country’s legislation, which may guarantee a lower standard of rights than the GDPR.
                </p>
                </li>
                <li>
                <b>California Resident Rights</b>
                <p className="paragraph p-adjustment text-left">
                The California Consumer Privacy Act (CCPA) and the California Online Privacy Protection Act (CalOPPA) require us to disclose the categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above. We are also required to communicate information about rights California residents have under California law. You may exercise the following rights: 
                <ul>
                    <li>
                    <b className='h-bold'>Right to Know and Access.</b> You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.
                    </li>
                    <li>
                    <b className='h-bold'>Right to Equal Service.</b> We will not discriminate against you if you exercise your privacy rights.
                    </li>
                    <li>
                    <b className='h-bold'>Right to Delete.</b> You may submit a verifiable request to close your Account and we will delete Personal Information about you that we have collected.
                    </li>
                    <li>
                    <b className='h-bold'>Right to request that a business that sells a consumer's personal data, does not sell the consumer's personal data.</b>
                    </li>
                </ul>
                </p>
                </li>
            </ol>
            <p className="paragraph p-adjustment text-left">
            If you make a request, we have one month to respond to you. We also do not sell the Personal Information of our Users. If you would like to exercise any of these rights or if you would like to receive any further information about these rights, please contact us at any time via email disclosed below.
            </p>
        </div>
    )
}