import React from 'react';

export default function Question4(){
    return(
        <div className="question mt-40">
            <h4 id="q4" className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    04.
                </span>
                What Do We Do With The Gathered Information
            </h4>
            <ol>
                <li>
                    <b>How Do We Use the Information We Collect?</b>
                    <p className="paragraph p-adjustment text-left">
                    Invoice Point acknowledges the importance of safeguarding sensitive permissions and high-risk data, and we are committed to ensuring their responsible and limited utilization. Sensitive permissions and high-risk data are accessed and used solely for the purpose of enhancing the functionality and security of the Invoice Point Application. It is important to note that Invoice Point adheres to industry-standard security measures to protect sensitive permissions and high-risk data. We do not share, sell, or disclose this data to third parties, except where explicitly required to fulfill the intended functionality of the Application or when compelled by applicable laws or regulations.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    By using the Invoice Point Application, users consent to the responsible and limited usage of sensitive permissions and high-risk data as outlined in this provision. Invoice Point encourages users to review and adjust their device's privacy settings to align with their preferences and comfort level regarding the access to and utilization of sensitive data.Any of the information we collect from you may be used in one of the following ways:   
                    <ul>
                        <li>
                        to personalize your experience (your information helps us to better respond to your individual needs);
                        </li>
                        <li>
                        to improve our App and Website (we continually strive to improve our App and Website offerings based on the information and feedback we receive from you);
                        </li>
                        <li>
                        to improve customer service (your information helps us to more effectively respond to your customer service requests and support needs);
                        </li>
                        <li>
                        to process transactions;
                        </li>
                        <li>
                        to administer a contest, promotion, survey, or other site feature;
                        </li>
                        <li>
                        In cases where location-based information is relevant, such as for tax calculations or generating location-specific invoices, Invoice Point may request access to your device's location data. This access is used exclusively for the purpose of providing accurate and context-aware invoicing features;
                        </li>
                        <li>
                        to send periodic emails, newsletters, promotions, and special offers. By submitting your email address on the App or the Website, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails. By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order.
                        </li>
                    </ul>
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy:
                    <ul>
                        <li>
                        to process your order or inquiries;
                        </li>
                        <li>
                        to diagnose or fix technology problems;
                        </li>
                        <li>
                        to control unauthorized use or abuse of the Services and our other products and services, or otherwise detect, investigate or prevent activities that may violate our policies or be illegal;
                        </li>
                        <li>
                        to prosecute and/or defend a court, arbitration, or similar legal proceeding, or to respond to lawful requests by public authorities (including national security or law enforcement requirements);
                        </li>
                        <li>
                        to analyze trends, administer or optimize the Services, monitor usage or traffic patterns (including to better understand how you use the Services), and gather demographic information about our user base as a whole;
                        </li>
                        <li>
                        to communicate directly with you by phone, including to respond to customer service inquiries or comments through the Services, on social media or otherwise, and to discuss issues relating to your account or the Services.
                        </li>
                    </ul>
                    </p>
                </li>
                <li>
                    <b>How Do We Share Your Information?</b>
                    <p className="paragraph p-adjustment text-left">
                    We reserve the right to disclose your personal information to specific third parties, including but not limited to advertisers, such as Google AdSense, payment processors, such as Stripe, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you and trusted third-party service providers responsible for hosting and maintaining the servers supporting the application. Additionally, in cases wherein compliance with pertinent laws or regulations is imperative, we may disclose such information to government or law enforcement authorities, as well as to private parties, as deemed necessary. This disclosure framework adheres to the principles of transparency and legal compliance governing the sharing of personal information with relevant entities or authorities.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    We may engage trusted third-party service providers (including but not limited to Third-Party Payment Services Providers) to perform functions and provide services to us, such as payment services, credit card processing, hosting and maintaining our servers and the App and the Website, database storage and management, email management, storage marketing, customer service, and fulfilling orders for products and services you may purchase through the App and the Website.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    We will likely share your personal data, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you. We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the App and/or the Website in a shared location, and the type of the Device used to visit the App and/or the Website. They may aggregate information about our advertising and what you see on the App and/or the Website and then provide auditing, research, and reporting for us and our advertisers.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    We may also disclose personal and non-personal data about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules, and regulations.
                    </p>
                    <ol type='a'>
                        <li>
                        <b>When does Invoice Point use end user information from third parties</b>
                        <p className="paragraph p-adjustment text-left">
                        Invoice Point will collect User personal data and the receiving party personal data necessary to provide the Invoice Point Services to our Users. Users and receiving parties may voluntarily provide us with the information they have made available on social media websites. If you provide us with any such information, we may collect publicly available information from the social media websites you have indicated. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.
                        </p>
                        </li>
                        <li>
                        <b>When does Invoice Point use customer information from third parties?</b>
                        <p className="paragraph p-adjustment text-left">
                        We receive some information from the third parties when you contact us. For example, when you submit your email address to us to show interest in becoming an Invoice Point User, we receive information from a third party that provides automated fraud detection services to Invoice Point. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.
                        </p>
                        </li>
                        <li>
                        <b>Where and when is information collected from Users and receiving parties?</b>
                        <p className="paragraph p-adjustment text-left">
                        Invoice Point will collect the personal data that you submit to us. We may also receive personal data about you from third parties as described above.
                        </p>
                        </li>
                    </ol>
                    <li>
                    <b>How Long Do We Keep Your Information?</b>
                    <p className="paragraph p-adjustment text-left">
                    We retain your personal data solely for the duration necessary to provide Invoice Point Services and fulfill the objectives articulated within this policy. This retention period similarly extends to any third parties with whom we share your information, provided they conduct services on our behalf. Upon cessation of the necessity for utilizing your personal data and in the absence of legal or regulatory obligations mandating its retention, we shall proceed to either expunge it from our systems or anonymize it to render identification impossible. It is pertinent to note that Dutch law imposes obligations pertaining to the retention of personal data, and the specific timeframes for such retention vary, encompassing periods spanning from several months to numerous years, contingent upon the applicable legal requirements.
                    </p>
                    </li>
                </li>
            </ol>
        </div>
    )
}