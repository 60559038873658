import React from 'react';
import { Fragment } from 'react';

export default function Terms12(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                12.
            </span>
            Cookies  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point uses "Cookies" to identify the areas of our Website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our App and Website but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the App or the Website as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all. We never place Personally Identifiable Information in Cookies. For more information regarding our use of Cookies, please see our Cookie Policy, accessible at: <a href="/cookie-policy">https://invoicepoint.app/cookie-policy</a>.
            </p>
            <p className="paragraph p-adjustment text-left">
            Please note that Third-Party Websites that may be accessible via the App or the Website may also use Cookies over which we have no control. User is responsible for checking the terms, the privacy policies and cookie policies applicable to relevant Third-Party Websites.
            </p>
            <p className="paragraph p-adjustment text-left">
            If you do not want to receive Cookies from Invoice Point you have an option of setting your browser to notify you when you receive a Cookie so that it is possible for you to determine whether to accept it or not. However, please be aware that turning off Cookies in your browser may prevent you from experiencing some of our Services.
            </p>
        </div>
        </Fragment>
    )
}