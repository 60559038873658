import React from 'react';
import { Fragment } from 'react';

export default function Terms7(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                07.
            </span>
            User Content 
            </h4>
            <p className="paragraph p-adjustment text-left">
            You retain all of your rights to the User Content.
            </p>
            <p className="paragraph p-adjustment text-left">
            You grant Invoice Point a worldwide, non-exclusive, royalty-free right to use, copy, store, transmit, distribute, publicly perform and display, create derivative works of and modify any User Content in order to provide the Services to you and otherwise to operate, improve and maintain the Services. This includes our distribution of invoices containing User Content to the receiving person designated by you. We may disclose and retain User Content collected for you only to provide the Services to you, comply with legal requirements, and exercise your rights under these Terms and applicable law.
            </p>
            <p className="paragraph p-adjustment text-left">
            We do not provide an archiving service. We agree only that we will not intentionally delete any User Content prior to termination or expiration of our Services you have bought. We expressly disclaim all other storage obligations to the extent permitted by applicable law. You are solely responsible for maintaining backups of User Content on your own systems.
            </p>
            <p className="paragraph p-adjustment text-left">
            Invoice Point will not store information regarding deleted invoices and any other User Content provided through the Services. After deleting an invoice or any other part of the User Content, you will no longer be able to retrieve information about the deleted User Content.
            </p>
            <p className="paragraph p-adjustment text-left">
            By signing your name electronically on our Services in the App or on the Website, you are agreeing that your electronic signature is the legal equivalent of your manual signature on that specific form.
            </p>
            <p className="paragraph p-adjustment text-left">
            We may share User Content when we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary and appropriate to: (i) satisfy any applicable law, subpoena, legal process or governmental request; (ii) enforce these Terms, including investigation of potential violations hereof; or (iii) protect against imminent harm to the App or Website or the rights, property or safety of Invoice Point, its Users or the public as required or permitted by applicable law.
            </p>
        </div>
        </Fragment>
    )
}