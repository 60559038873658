import React from 'react';
import { Fragment } from 'react';

export default function Terms11(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                11.
            </span>
            Privacy Policy  
            </h4>
            <p className="paragraph p-adjustment text-left">
            When submitting personal data through our App and Website, you agree to our Privacy Policy (made available on the Website at <a href='https://invoicepoint.app/privacy-policy'>https://invoicepoint.app/privacy-policy</a>) (the “Privacy Policy”) and consent to the gathering, use and disclosure of this data in a way that is described in the Privacy Policy.
            </p>
            <p className="paragraph p-adjustment text-left">
            For information regarding how we collect, use, store and disclose some of your personal information, please refer to our Privacy Policy. You acknowledge that the Privacy Policy is an integral part of these Terms, and by using the Website and the Services, you also agree that you have read, understood, and accepted the terms of the Privacy Policy.
            </p>
            <p className="paragraph p-adjustment text-left">
            The security of your data is important to us, but no method of transmission over the internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security and we do not take any liability in case of security breaches.
            </p>
        </div>
        </Fragment>
    )
}