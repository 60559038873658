import React from 'react';
import { Fragment } from 'react';

export default function Cookie3(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                03.
            </span>
            Consent under GDPR 
            </h4>
            <p className="paragraph p-adjustment text-left">
            We are committed to complying with the GDPR. As part of our commitment:
            <ol type='1'>
                <li>
                <b>Explicit Consent:</b><br/>
                We request your explicit consent for non-essential cookies when you visit our Website. You have the choice to accept or reject non-essential cookies.
                </li>
                <li>
                <b>Withdrawal of Consent:</b><br/>
                You can change your cookie preferences at any time by adjusting your browser settings. Also, our Website provides a feature for you to withdraw consent for non-essential cookies.
                </li>
            </ol>
            </p>
        </div>
        </Fragment>
    )
}