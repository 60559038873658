import React from 'react';
import { Fragment } from 'react';

export default function Payment1(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold ">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                01.
            </span>
            Getting Started
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ul>
                <li>
                <b>Account Setup:</b>
                <ol>
                    <li>
                    <b>Creating an Account:</b> To get started, sign up for an Invoice Point account. Provide accurate personal and business information during the registration process.
                    </li>
                    <li>
                    <b>Verifying Your Email:</b> After registration, you'll receive a verification email. Be sure to verify your email address to activate your account.
                    </li>
                    <li>
                    <b>Linking Your Stripe Account:</b> If you wish to leverage the full potential of Invoice Point's payment system, securely link your Stripe account.
                    </li>
                </ol>
                </li>
                <li>
                <b>Account Setup:</b>
                <ol>
                    <li>
                    <b>Navigating to the Payment Section:</b> Access the payment features by navigating to the payment section within the application's dashboard.
                    </li>
                    <li>
                    <b>Understanding the Dashboard:</b> Familiarize yourself with the dashboard layout and key functionalities.
                    </li>
                    <li>
                    <b>Available Payment Methods:</b> Discover the various payment methods available in the dashboard, each tailored to meet distinct payment needs.
                    </li>
                </ol>
                </li>
            </ul>
            </p>
        </div>
        </Fragment>
    )
}