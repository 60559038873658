import React from 'react';
import { Fragment } from 'react';

export default function Terms2(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                02.
            </span>
                Definitions and Key Terms 
            </h4>
            <p className="paragraph p-adjustment text-left">
                To help explain things as clearly as possible in these Terms, every time any of the below listed terms are referenced, they are strictly defined as:
            </p>
            <ul>
            <li className="paragraph p-adjustment text-left">
                <b>Account:</b> your registered account that is required to use Invoice Point Services.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>App:</b> means the Invoice Point mobile application, as available on the Apple App Store for iOS Devices and Google Play for Android Devices.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Cookie:</b> small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, and remember information about you such as your language preference or login information. 
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Company:</b> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Invoice Point, which is responsible for your information under these Terms. 
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Country:</b> where Invoice Point or the owners/founders of Invoice Point are based, in this case, is the Netherlands.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Device:</b> any internet-connected device such as a phone, tablet, computer, or any other device that can be used to access the App, visit the Website and use the Services.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Force Majeure:</b> force majeure events include any provision of any present or future government law, regulation, order or action; any act of God; war invasion, hostilities, terrorism, riots and other civil unrest; natural disasters, such as flood, fire, earthquake, tsunami or explosion as well as any epidemics or pandemics;  the unavailability, disruption or malfunction of the internet, telecommunication breakdown, shortage of adequate power or electricity; strikes, labour stoppages or other industrial disturbances; any consensus attack, or hack, or denial-of-service or other attack on the foregoing on the other software, networks and infrastructure that enables us to provide our App, Website and Services.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Promotions:</b> refer to contests, promotions, sweepstakes, or other promotional activities organised by or organised in cooperation with Invoice Point that require you to submit material or information by the Users or other persons.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Registration Data:</b> refers to information as may be prompted by the registration forms via the App or the Website for a successful registration with Invoice Point and creation of an Account. 
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Services:</b> refers to the services provided by Invoice Point as described in these Terms and accessible through the App and the Website.  
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Suggestions:</b> collectively refers to any feedback, comments, ideas, improvements, or suggestions from the Users.  
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Third-Party Payment Services Providers:</b> refers to any third-party payment services providers that Invoice Point works with and are used to provide payment options in the App or the Website for the Services.  
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Third-Party Service:</b> refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide content (including data, information, applications, and other products services) or whose products or services we think may interest you or provide links to third-party websites or services.   
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Updates:</b> refer to enhancements or improvements to the features/functionality of the App and the Website, which may include patches, bug fixes, updates, upgrades, and other modifications.   
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>User Content:</b> refers to any information that Users submit through the Services on the App or Website.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>Website:</b> refers to the Invoice Point website, which can be accessed on this URL: <a href='https://www.invoicepoint.app'>https://www.invoicepoint.app</a>.
            </li>
            <li className="paragraph p-adjustment text-left">
                <b>You:</b> a person or entity that is registered with Invoice Point to use the Services.
            </li>
            </ul>
        </div>
        </Fragment>
    )
}