import React from 'react';

export default function Question11(){
    return(
        <div className="question mt-40">
            <h4 id='q11' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    12.
                </span>
                Your Consent
            </h4>
            <p className="paragraph p-adjustment text-left">
            This update to our Privacy Policy is aimed at furnishing you with a comprehensive understanding of the data practices associated with your use of our App and/or visit to our Website, with particular emphasis on the handling of your personal data and information. By virtue of using our Application and/or Website, engaging in the registration of an Account, or effecting a purchase through our platform, you hereby provide explicit consent to the terms and conditions set forth in our updated Privacy Policy. It is imperative that you review and acquaint yourself with the provisions contained therein to ensure your full awareness and compliance with our data handling practices.
            </p>
        </div>
    )
}