import React from 'react';

export default function Question20(){
    return(
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    21.
                </span>
                How To Contact Us
            </h4>
            <p className="paragraph p-adjustment text-left">
            If you have any questions or otherwise need to contact us in connection with this Privacy Policy, the App, the Website or the Services, please contact us at:
            </p>
            <p className="paragraph p-adjustment text-left">
            <b className='h-bold'>Invoice Point</b><br/>
            Address: De Specerij 25, Almere, 1313 NC, Netherlands<br/>
            Email: <a href='mailto: support@invoicepoint.app'>support@invoicepoint.app</a>
            </p>
        </div>
    )
}