import React from 'react';
import { Fragment } from 'react';

export default function Terms16(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                13.
            </span>
            Third-Party Services  
            </h4>
            <p className="paragraph p-adjustment text-left">
            We may display, include or make available Third-Party Services. You acknowledge and agree that Invoice Point shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Invoice Point does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions. 
            </p>
        </div>
        </Fragment>
    )
}