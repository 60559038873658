import React from 'react';

export default function Question5(){
    return(
        <div className="question mt-40">
            <h4 id='q5' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    06.
                </span>
                User Account Deletion
            </h4>
            <p className="paragraph p-adjustment">
            When you choose to delete your account, you have the option to request the deletion of all your user data from the App. We understand that circumstances may change, and it's important to have control over your personal information. By initiating the account deletion process and requesting data deletion, you can ensure that your information is securely removed from our systems. We take your privacy seriously and strive to provide a seamless experience. Rest assured that your data will be handled in accordance with our privacy policies and procedures. In case you are unable to perform the deletion yourself, you can contact us at <a style={{marginLeft:"5px"}} href={"mailto:{support@invoicepoint.app}"}>
                        support@invoicepoint.app
                    </a>
            </p>
        </div>
    )
}