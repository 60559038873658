import React from 'react';
import { Fragment } from 'react';

export default function Payment4(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                04.
            </span>
            Receiving Payments 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ul>
                <li>
                <b>Processing time</b>
                <p className="paragraph p-adjustment text-left">
                The time it takes to process payments can differ based on the payment method and financial institutions used. Typically, payments made through methods like credit/debit cards, PayPal, Stripe, or online banking are processed quickly, usually within minutes. On the other hand, bank transfers or traditional methods may require a few business days to finalize. For the most precise details on payment processing times, consult the documentation provided by the specific payment method.</p>
                <p className="paragraph p-adjustment text-left">
                    It is also important to know that your initial transfer may take longer depending on your industry risk level and country of operation. Please refer to Stripe <a href='https://docs.stripe.com/payouts'>support page</a> for more information.
                </p>
                </li>
                <li>
                <b>App Notifications:</b>
                <p className="paragraph p-adjustment text-left">
                You can configure app notifications within your Invoice Point settings. Download the Invoice Point mobile app to receive real-time payment updates directly on your mobile device. Customize your notification preferences to suit your needs, ensuring you never miss an important payment notification.
                </p>
                <p className="paragraph p-adjustment text-left">
                App notifications, when enabled, offer real-time updates directly on your mobile device. These notifications may include additional information, such as payment timestamps and status changes. Utilize app notifications for immediate access to payment-related updates while on the go.
                </p>
                </li>
            </ul>
            </p>
        </div>
        </Fragment>
    )
}