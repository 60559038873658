import React from 'react';
import { Fragment } from 'react';

export default function Terms10(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                10.
            </span>
            Links to Third-Party Websites  
            </h4>
            <p className="paragraph p-adjustment text-left">
            These Terms apply only to the Services. The Services may contain links to other websites not operated or controlled by Invoice Point. We are not responsible for the content, accuracy, or opinions expressed in such websites, and such websites are not investigated, monitored, or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Terms are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our Website, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.
            </p>
        </div>
        </Fragment>
    )
}