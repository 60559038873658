import React from 'react';

export default function Question2(){
    return(
        <div className="question mt-40">
            <h4 id='q2' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    02.
                </span>
                Definitions
            </h4>
            <p className="paragraph p-adjustment text-left">
            "Personal data" (in the context of this Privacy Policy) means any information identifying or describing an identifiable individual, including, but not limited to, information relating to their name, age, gender, reproductive status, marital status, national, ethnic or social origin, sexual orientation, physical or mental health, disability, conscience, belief, culture, language and birth of the individual; educational, criminal or employment history of the individual, financial status or information relating to financial transactions in which they have been involved and any identifying number, address or contact information of the individual. 
            </p>
            <p className="paragraph p-adjustment text-left">
            The following terms “controller”, “processor”, “data subject”, “processing activity/ies”, “pseudonymisation”, “cross-border processing of personal data”, “supervisory authority” used in this document shall have the same meaning as in the GDPR unless relevant to a different law or regulation.
            </p>
            <p className="paragraph p-adjustment text-left">
            For purposes of this Privacy Policy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by, or is under common control with Invoice Point, whether by ownership or otherwise.
            </p>
            <p className="paragraph p-adjustment text-left">
            Any other terms which are used throughout this Privacy Policy shall have their respective meanings, as defined in our standard Terms, unless the context indicates otherwise.
            </p>
        </div>
    )
}