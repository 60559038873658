import React from 'react';
import { Fragment } from 'react';

export default function Cookie4(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                04.
            </span>
            What types of cookies do we use? 
            </h4>
            <p className="paragraph p-adjustment text-left">
            We use the following types of cookies on our Website:
            <ol type='1'>
                <li>
                <b>Essential Cookies:</b><br/>
                These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.
                </li>
                <li>
                <b>Analytics and Performance Cookies:</b><br/>
                These cookies are used to collect information about traffic to our Website and how users use our Website. The information gathered does not identify any individual visitor.
                </li>
                <li>
                <b>Functionality Cookies:</b><br/>
                These cookies allow our Website to remember choices you make while browsing. For example, we may store your geographic location in a cookie to ensure that we show you our website localized for your area.
                </li>
                <li>
                <b>Targeting or Advertising Cookies:</b><br/>
                These cookies are used to make advertising messages more relevant to you. They perform functions like preventing the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some cases selecting advertisements that are based on your interests.
                </li>
            </ol>
            </p>
        </div>
        </Fragment>
    )
}