import React from 'react';

export default function Question3(){
    return(
        <div className="question mt-40">
            <h4 id='q3' className="h-4 h-bold">
                <span style={{opacity:"0.4", marginRight:"10px"}}>
                    03.
                </span>
                What Personal Information Do We Collect?
            </h4>
            <ol>
                <li>
                <b>General information</b>
                <p className="paragraph p-adjustment">
                When you use our App and/or Website we collect some of your personal data. This enables us to effectively provide you with the Services in our App and on our Website. All personal data will be processed lawfully, fairly and transparently. We collect information from you when you use our App, visit our Website, register with our Services, place an order, sign a contract, contact us through one of our channels, subscribe to our newsletter, respond to a survey, or fill out a form. We also gather some of your data by keeping track of your interaction with our App and/or Website by using cookies and similar technologies. In order to make our Website available to you we may furthermore obtain and process certain data.
                </p>
                </li>
                <li>
                <b>The personal data we collect</b>
                <p className="paragraph p-adjustment">
                In accordance with our privacy practices, we exclusively gather personal data for predefined and legitimate purposes. The nature and extent of data collection and processing are contingent upon your interactions with our Application and/or Website, as well as the privacy preferences and choices you exercise.
                </p>
                <p className="paragraph p-adjustment">
                Personal information we collect:
                </p>
                <ul>
                    <li>
                    <b>Identification data </b>
                    <span className="paragraph p-adjustment">
                    (such as your name, surname, date and place of birth, ID number, and the IP address of your computer, mobile phone or other Device);
                    </span>
                    </li>
                    <li>
                    <b>Contact information </b>
                    <span className="paragraph p-adjustment">
                    (such as personal name, email, phone number, address, etc.);
                    </span>
                    </li>
                    <li>
                    <b>Account log-in credentials </b>
                    <span className="paragraph p-adjustment">
                    (such as your email address or usernames and passwords);
                    </span>
                    </li>
                    <li>
                    <b>Social media profile information </b>
                    <span className="paragraph p-adjustment">
                    of Users, User account information and information regarding the use of our Website may also be collected. Social media URLs, profile description and profile image are however not necessary and are not required for Users to enter;
                    </span>
                    </li>
                    <li>
                    <b>Financial information </b>
                    <span className="paragraph p-adjustment">
                    (such as bank account numbers, bank statement and trading information, digital wallet information);
                    </span>
                    </li>
                    <li>
                    <b>Device information: </b>
                    <span className="paragraph p-adjustment">
                    (such as bank account numbers, bank statement and trading information, digital wallet information);
                    </span>
                    </li>
                    <li>
                    <b>Usage data: </b>
                    <span className="paragraph p-adjustment">
                    data about your activity on and use of our offerings, such as app launches within our Services, including browsing history; search history; product interaction; crash data, performance and other diagnostic data; and other usage data;
                    </span>
                    </li>
                    <li>
                    <b>Technical information: </b>
                    <span className="paragraph p-adjustment">
                    including the Internet protocol (IP) address used to connect your computer to the Internet, the User login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; information about the visits, including the full Uniform Resource Locators (URL), clickstream to, through and from the Website (including date and time), products viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number;
                    </span>
                    </li>
                    <li>
                    <b>Other information you provide to us: </b>
                    <span className="paragraph p-adjustment">
                    details such as the content of your communications with Invoice Point.
                    </span>
                    </li>
                </ul>
                <br/>
                <p className="paragraph p-adjustment">
                We also collect information from mobile Devices for a better user experience, although these features are completely optional:
                </p>
                <ul>
                    <li>
                    <b>location (GPS): </b>
                    <span className="paragraph p-adjustment">
                    location data helps to create an accurate representation of your interests, and this can be used to bring more targeted and relevant ads to potential customers;
                    </span>
                    </li>
                    <li>
                    <b>photo gallery (pictures): </b>
                    <span className="paragraph p-adjustment">
                    granting photo gallery access allows the user to upload any picture from their photo gallery, you can safely deny photo gallery access for the App or the Website;
                    </span>
                    </li>
                    <li>
                    <b>camera (pictures): </b>
                    <span className="paragraph p-adjustment">
                    granting camera permission allows the User to upload any picture straight to the App or the Website, you can safely deny camera permissions for this.
                    </span>
                    </li>
                </ul>
                </li>
                <li>
                <b>Your options</b>
                <p className="paragraph p-adjustment">
                You retain the choice to determine the data we collect. You have the option to decline the provision of your personal information when solicited. It is important to note, however, that if you choose not to furnish us with the requisite data, which is essential for the proper functioning of the Application and/or Website, you may encounter certain limitations in your ability to effectively utilize our services.
                </p>
                </li>
            </ol>
            <p className="paragraph p-adjustment">
                
            </p>
        </div>
    )
}