import React from 'react';
import Terms1 from './t01';
import Terms2 from './t02';
import Terms3 from './t03';
import Terms4 from './t04';
import Terms5 from './t05';
import Terms6 from './t06';
import Terms7 from './t07';
import Terms8 from './t08';
import Terms9 from './t09';
import Terms10 from './t10';
import Terms11 from './t11';
import Terms12 from './t12';
import Terms13 from './t13';
import Terms14 from './t14';
import Terms15 from './t15';
import Terms16 from './t16';
import Terms17 from './t17';
import Terms18 from './t18';
import Terms19 from './t19';
import Terms20 from './t20';
import Terms21 from './t21';
import Terms22 from './t22';
import Terms23 from './t23';
import Terms24 from './t24';
import Terms25 from './t25';
import Terms26 from './t26';
import Terms27 from './t27';
import Terms28 from './t28';
import Terms29 from './t29';
import Terms30 from './t30';
import Terms31 from './t31';
import Terms32 from './t32';
import Terms33 from './t33';
import Terms34 from './t34';
import Terms35 from './t35';

export default function Terms(){
    return(
        <section className="sp-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="h-1 h-bold text-left">
                            Invoice Point Terms & Conditions
                        </h1>
                        <p className="paragraph p-adjustment text-left">
                            Last Updated on 2024-02-09
                        </p>
                        <Terms1 />
                        <Terms2 />
                        <Terms3 />
                        <Terms4 />
                        <Terms5 />
                        <Terms6 />
                        <Terms7 />
                        <Terms8 />
                        <Terms9 />
                        <Terms10 />
                        <Terms11 />
                        <Terms12 />
                        <Terms13 />
                        <Terms14 />
                        <Terms15 />
                        <Terms16 />
                        <Terms17 />
                        <Terms18 />
                        <Terms19 />
                        <Terms20 />
                        <Terms21 />
                        <Terms22 />
                        <Terms23 />
                        <Terms24 />
                        <Terms25 />
                        <Terms26 />
                        <Terms27 />
                        <Terms28 />
                        <Terms29 />
                        <Terms30 />
                        <Terms31 />
                        <Terms32 />
                        <Terms33 />
                        <Terms34 />
                        <Terms35 />
                    </div>
                </div>
            </div>
        </section>
    )
}