import React from 'react';
import { Fragment } from 'react';

export default function Terms23(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                23.
            </span>
            Severability  
            </h4>
            <p className="paragraph p-adjustment text-left">
            If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect. These Terms, together with the Privacy Policy and any other legal notices published by Invoice Point on the Services, shall constitute the entire agreement between you and Invoice Point concerning the Services. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Invoice Point's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.  
            </p>
            <p className="paragraph p-adjustment text-left">
            YOU AND INVOICE POINT AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
            </p>
        </div>
        </Fragment>
    )
}