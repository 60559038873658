import React from 'react';
import { Fragment } from 'react';

export default function Terms21(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                21.
            </span>
            Limitation of Liability  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Notwithstanding any damages that you might incur, the entire liability of Invoice Point and any of its suppliers under any provision of these Terms and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the App or the Website. To the maximum extent permitted by applicable law, in no event shall Invoice Point or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, loss of profits, loss of data or other information, for business interruption, failure of security mechanisms, loss of privacy arising out of or in any way related to the use of or inability to use the App and the Website, third-party software and/or third-party hardware used with the App or the Website, or otherwise in connection with any provision of these Terms), even if Invoice Point or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose. Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.  
            </p>
        </div>
        </Fragment>
    )
}