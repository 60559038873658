import React from 'react';
import { Fragment } from 'react';

export default function Terms18(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                18.
            </span>
            Copyright Infringement Notice  
            </h4>
            <p className="paragraph p-adjustment text-left">
            If you are a copyright owner or such owner's agent and believe any material on our App or Website constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorised to act on his behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including your address, telephone number, and an email; (d) a statement by you that you have a good faith belief that use of the material is not authorised by the copyright owners; and (e) the statement that the information in the notification is accurate, and, under penalty of perjury you are authorised to act on behalf of the owner.
            </p>
        </div>
        </Fragment>
    )
}