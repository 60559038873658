import React from 'react';
import { Fragment } from 'react';

export default function Terms6(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                06.
            </span>
            Payments 
            </h4>
            <ol type='1'>
                <li><b>Third-Party Payment Services Providers</b>
                    <p className="paragraph p-adjustment text-left">
                    We use Paypal.me, Stripe, Bank Transfer, or any custom payment request link provided by the user as the Third-Party Payment Services Providers. By processing payments through our Services in the App or on the Website and by clicking on any link of Third-Party Payment Services Providers provided in the App or on the Website, you acknowledge you are no longer using our Services and therefore our Terms, Privacy Policy and any other documents by Invoice Point are not applicable anymore and you agree to be bound by any terms and conditions, privacy policies or other policies of Third-Party Payment Services Providers, including but not limited to:
                    </p>
                    <ul>
                    <li className="paragraph p-adjustment text-left">
                    Paypal.me's Terms and Conditions, Service Agreement, Privacy Policy and other documents, accessible on: <a href='https://www.paypal.me'>https://www.paypal.me</a>.
                    </li>
                    <li className="paragraph p-adjustment text-left">
                        Stripe’s Terms and Conditions are accessible on: <a href='https://stripe.com/legal/ssa'>https://stripe.com/legal/ssa</a>
                    </li>
                    </ul>
                    <p className="paragraph p-adjustment text-left">
                    You hereby consent and authorise Invoice Point and its third-party payment processing services to share any information or payment instructions you provide with Third-Party Payment Services Providers to the minimum extent required to complete your transactions.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Invoice Point shall not be liable for any transactions or other operations you may do with any Third-Party Payment Services Providers and you shall indemnify Invoice Point for such actions. You, the User understand and agree that Invoice Point shall not be held liable for any payments and monetary transactions that occur through Your use of the Service and that all such payments and transactions are handled by Stripe. You agree that Invoice Point shall not be held liable for any issues regarding financial and monetary transactions between You and any other party, including Stripe. You are solely responsible for all transactions (one-off, product purchases, recurring subscriptions, refunds and cancellations) processed through Invoice Point, and/or Stripe, or any other third-party payment processing services used by Invoice Point. Additionally, we will not be liable for any losses or damages arising from invalid or fraudulent transactions through usage of the Service or connected Stripe accounts. This includes but is not limited to transactions that were not processed due to a network communication issue. If you process a transaction, it is Your responsibility to ensure it has been fully settled.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Invoice Point shall not be liable for any transactions or other operations you may do with any Third-Party Payment Services Providers and you shall indemnify Invoice Point for such actions.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    We will do our best to notify you when you leave our App or Website or otherwise use any services or products provided by Third-Party Payment Services Providers but may not always do so for the sake of functionality and practicality of our App and Website. Invoice Point shall not be held liable for any such omission to the maximum extent permitted by law.
                    </p>
                </li>
                <li>
                <b>Fees</b>
                <p className="paragraph p-adjustment text-left">
                For paid subscriptions, you must pay all fees and taxes to Invoice Point through Third-Party Payment Services Providers or other authorised third party (e.g., Apple App Store, Google Play) through which you made your purchase. All fees are non-refundable except as expressly specified in the Refund Policy section 6.5 of this Article.
                </p>
                </li>
                <li>
                <b>Fee Changes</b>
                <p className="paragraph p-adjustment text-left">
                We may, in our sole discretion and at any time, modify the fees for the Services. Any fee change will become effective at the end of the billing cycle if a subscription-based Services fee is changed.
                </p>
                <p className="paragraph p-adjustment text-left">
                We will provide you with a reasonable prior notice of any change in the fees to give you an opportunity to terminate any subscriptions of Services before such change becomes effective.
                </p>
                <p className="paragraph p-adjustment text-left">
                Your continued use of the Services after the fee change comes into effect constitutes your agreement to pay the modified subscription fee amount.
                </p>
                </li>
                <li>
                <b>Taxes</b>
                <p className="paragraph p-adjustment text-left">
                You accept and acknowledge that you are solely responsible for determining what, if any, taxes apply to Invoice Point related transactions. Invoice Point is not responsible for determining the taxes that apply to the transactions you make in the App or on the Website.
                </p>
                </li>
                <li>
                <b>Disclaimer</b>
                <p className="paragraph p-adjustment text-left">
                Any payment regarding the User Content (specifically the invoices) is solely between you and the receiver of the funds. You hereby acknowledge that we are not a party to your transactions and assume no liability or responsibility for any of your products, services or offerings. We are also not your bank, agent, or trustee or otherwise involved in the flow of funds or payments. Any payment dispute must be resolved between you and the receiver of the funds.
                </p>
                <p className="paragraph p-adjustment text-left">
                To the maximum extent permitted by applicable law, we make no warranty and assume no responsibility or liability for any disputes, chargebacks, overcharges, delayed payments, insufficient funds, expired cards, circumstances beyond our control (including without limitation power outages, service interruptions or otherwise) or other issues relating to transactions made through the relevant Third-Party Payment Services Providers.
                </p>
                </li>
            </ol>
        </div>
        </Fragment>
    )
}