import React from 'react';
import { Fragment } from 'react';

export default function Payment10(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                10.
            </span>
            Legal Disclaimer 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ol>
                <li>
                    <b>General Information:</b>
                    <p className="paragraph p-adjustment text-left">
                    The content provided within this guide and on the Invoice Point platform is intended solely for informational purposes. It does not constitute legal, financial, or professional advice. Users are encouraged to consult with qualified professionals or legal advisors for specific financial, legal, or business-related matters.
                    </p>
                </li>
                <li>
                    <b>Accuracy of Information:</b>
                    <p className="paragraph p-adjustment text-left">
                    We strive to ensure the accuracy and currency of the information presented. However, we do not warrant or guarantee the completeness, accuracy, or reliability of any information provided herein. Users should independently verify any information and rely on it at their own risk.
                    </p>
                </li>
                <li>
                    <b>Use of Services:</b>
                    <p className="paragraph p-adjustment text-left">
                    The use of Invoice Point's services, including the payment system, is subject to the terms and conditions outlined in the platform's user agreement. Users are strongly advised to review and understand the user agreement thoroughly before engaging with our services.
                    </p>
                </li>
                <li>
                    <b>Third-Party Links:</b>
                    <p className="paragraph p-adjustment text-left">
                    This guide may contain links to third-party websites or resources for reference purposes. These links do not imply endorsement or responsibility for the content, security, or privacy practices of external websites. Users should exercise caution and review the terms of use and privacy policies of linked websites independently.
                    </p>
                </li>
                <li>
                    <b>Liability Limitation:</b>
                    <p className="paragraph p-adjustment text-left">
                    To the fullest extent permitted by applicable law, Invoice Point and its affiliates shall not be held liable for any direct or indirect damages, losses, or claims arising from the use of our services or reliance on information provided, including but not limited to financial losses, business interruptions, or data inaccuracies.
                    </p>
                </li>
                <li>
                    <b>Legal Compliance:</b>
                    <p className="paragraph p-adjustment text-left">
                    Users are responsible for ensuring compliance with all relevant local, national, and international laws, regulations, and taxation requirements when using Invoice Point's services. We do not provide legal or tax advice, and users should seek professional counsel for legal or tax-related matters.
                    </p>
                </li>
                <li>
                    <b>Modification of Information:</b>
                    <p className="paragraph p-adjustment text-left">
                    Invoice Point reserves the right to update, modify, or remove any information provided within this guide or on the platform at any time, without prior notice. Users should regularly check for updates and changes. Additionally, we commit to notifying you of any forthcoming updates or changes to the payment system, ensuring you remain well-informed and prepared for any enhancements or modifications.
                    </p>
                </li>
                <li>
                    <b>No Endorsement:</b>
                    <p className="paragraph p-adjustment text-left">
                    Any mention of specific products, services, or third-party entities within this guide or on the platform is for informational purposes only and does not constitute an endorsement or recommendation. Invoice Point does not endorse, guarantee, or warrant the quality or suitability of any third-party products or services.
                    </p>
                </li>
                <li>
                    <b>Indemnification:</b>
                    <p className="paragraph p-adjustment text-left">
                    Users agree to indemnify and hold Invoice Point and its affiliates harmless from any claims, losses, liabilities, or expenses arising from their use of our services, including any violation of these terms and conditions.
                    </p>
                </li>
                <li>
                    <b>Governing Law:</b>
                    <p className="paragraph p-adjustment text-left">
                    These disclaimers and terms of use shall be governed by and construed in accordance with the laws of the jurisdiction in which Invoice Point operates, without regard to its conflict of law principles.
                    </p>
                </li>
            </ol>
            </p>
        </div>
        </Fragment>
    )
}