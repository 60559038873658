import React from 'react';
import { Fragment } from 'react';

export default function Payment2(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                02.
            </span>
            Generating Invoices 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ul>
                <li>
                <b>Creating Invoices:</b>
                <ol>
                    <li>
                    <b>How to Start the Invoice Creation Process:</b> Initiate the invoice creation process by clicking on "Create Invoice" within the payment section. Follow the prompts to enter essential invoice details.
                    </li>
                    <li>
                    <b>Customizing Invoice Details:</b> Personalize your invoices with your logo, business information, and terms and conditions.
                    </li>
                    <li>
                    <b>Adding Items and Pricing:</b> Specify the products or services provided, along with their corresponding prices.
                    </li>
                </ol>
                </li>
                <li>
                <b>Sending Invoices:</b>
                <ol>
                    <li>
                    <b>Options for Sharing Invoices:</b> After crafting your invoices, you have multiple options for sharing them with clients, including email, direct messaging, and downloading.
                    </li>
                    <li>
                    <b>Setting Due Dates and Reminders:</b> Establish due dates and configure reminders to ensure timely payments.
                    </li>
                    <li>
                    <b>Tracking Sent Invoices:</b> Monitor the payment status of invoices you've sent to clients within the dashboard.
                    </li>
                </ol>
                </li>
            </ul>
            </p>
        </div>
        </Fragment>
    )
}