import React from 'react';
import { Fragment } from 'react';

export default function Terms26(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                26.
            </span>
            Entire Agreement 
            </h4>
            <p className="paragraph p-adjustment text-left">
            The Terms constitute the entire agreement between you and Invoice Point regarding your use of the App and the Website and supersedes all prior and contemporaneous written or oral agreements between you and Invoice Point. You may be subject to additional terms and conditions that apply when you use or purchase other Invoice Point's services, which Invoice Point will provide to you at the time of such use or purchase.   
            </p>
        </div>
        </Fragment>
    )
}