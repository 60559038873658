import React from 'react';
// import FAQ1 from './f1';
import FAQ2 from './f2';
import FAQ3 from './f3';
import FAQ4 from './f4';
import FAQ5 from './f5';


export default function FAQ(){
    return(
        <section className="sp-90">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-lg-2">
                        <h1 className="h-1 h-bold text-left">
                            Frequently Asked Questions
                        </h1>
                        <p className="paragraph p-adjustment text-left">
                        <br/>
                        <h5>
                            <ul>
                                <li>
                                <a href="/online-payments">How to use Online Payments</a>
                                </li>
                            </ul>
                        </h5>
                        </p>
                        <div className="mt-40">
                            {/* <FAQ1 /> */}
                            <FAQ2 />
                            <FAQ3 />
                            <FAQ4 />
                            <FAQ5 />                     
                        </div>
                    </div>             
                </div>
            </div>

        </section>
    )
}