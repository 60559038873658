import React from 'react';
import { Fragment } from 'react';

export default function Terms15(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                13.
            </span>
            Updates to Our App and Website  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point may from time to time provide Updates. Updates may modify or delete certain features and/or functionalities of the App or the Website. You agree that Invoice Point has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the App or the Website to you. You further agree that all Updates will be (i) deemed to constitute an integral part of the App or the Website, and (ii) subject to the terms and conditions of these Terms.
            </p>
        </div>
        </Fragment>
    )
}