import React from 'react';
import { Fragment } from 'react';

export default function Terms3(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                03.
            </span>
                License 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the App or use the Website strictly in accordance with the Terms. These Terms are a contract between you and Invoice Point (referred to in these Terms as "Invoice Point", "us", "we" or "our"), the provider of the Invoice Point App, Website, and the Services accessible through them. You are agreeing to be bound by these Terms.      If you violate any provision of these Terms, we reserve the right to cancel your Account or block access to your Account without notice.
            </p>
        </div>
        </Fragment>
    )
}