import React from 'react';
import { Fragment } from 'react';

export default function Terms4(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                04.
            </span>
                Services 
            </h4>
            <ol type='1'>
                <li><b>General</b>
                    <p className="paragraph p-adjustment text-left">
                    Our Services may direct Users to make payments for their invoices, where we cooperate with Third-Party Payment Services Providers, as described in Article 6, Payments.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    The features, period of usage and other additional specific terms of the Services are dependent on the chosen usage plan, as set out in Sub-Articles 4.1 and 4.2 below.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    To use our App, Website and Services offered, Users have to choose a usage package/plan. After choosing a paid invoice usage package/plan, Users will be given a 7-day trial period during which they can cancel their selected plan without charge.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    Access to certain restricted sections of the Invoice Point Application is available to registered users, and can be accomplished through the following five-step process:
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b>Step 1: Account Setup</b><br/>
                    Start by creating a valid Invoice Point account. We'll ask for some necessary details during the registration process.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b>Step 2: Generate Invoices</b><br/>
                    Once you’ve got your account set up, it’s time to create invoices. Pick a template, add your logo, customize the invoice to your liking, add client info, and items. Voilà, your invoice is good to go!
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b>Step 3: Customize Your Invoices</b><br/>
                    Want to give your invoices a personal touch? Head to the settings where you can choose layouts, add your company logo, and even use banners or watermarks. Play around with colors to get the right look.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b>Step 4: Sending Invoices and Estimates</b><br/>
                    We make it super easy to send invoices. You can shoot them off through text, email, or even via WhatsApp and Facebook Messenger. Sending estimates is just as simple – select 'Quotes' from the menu, fill in client details, add items, and your estimate is ready to roll!
                    </p>
                    <p className="paragraph p-adjustment text-left">
                    <b>Step 5: Access Business Reports</b><br/>
                    Curious about your business stats? Go to the 'Reports' section in the home menu. You'll find a bunch of useful reports there, like outstanding balances, annual and monthly sales figures, and much more.
                    </p>
                </li>
                <li><b>Usage Plans and Fees</b>
                    <p className="paragraph p-adjustment text-left">
                        Invoice Point offers various usage plans for the Services, including:
                        <ul>
                            <li className="paragraph p-adjustment text-left">
                                Free trials, which offer limited functionality and will expire if not converted to a paid plan. If you have a free trial plan, you may use the Services solely to determine whether to purchase a paid subscription or package.
                            </li>
                            <li className="paragraph p-adjustment text-left">
                                Paid invoice packages/plans, which let you purchase a specific number of invoices to be used within a defined period of time from the purchase date.
                            </li>
                        </ul>
                    </p>
                </li>
                <li><b>Features</b>
                    <p className="paragraph p-adjustment text-left">
                        The features available and the number of invoices and users depend on the plan you select. Current plans are described at <a href='/pricing'>https://www.invoicepoint.app/pricing</a>.
                    </p>
                    <p className="paragraph p-adjustment text-left">
                        Your specific plan will be identified in the terms and conditions presented when you register your Account, order, or pay for the Services. These terms incorporate any referenced URLs and Invoice Point policies. Depending on how you use the Services, the order terms may be presented by Invoice Point, a third-party application store or marketplace, or through the Services itself. Paid subscriptions and plans are for limited subscription terms and, if not renewed, the Services will revert to limited functionality once a subscription term expires. 
                    </p>
                </li>
            </ol>
        </div>
        </Fragment>
    )
}