import React from 'react';
import { Fragment } from 'react';

export default function Terms29(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                29.
            </span>
            Notice for California Resident Users 
            </h4>
            <p className="paragraph p-adjustment text-left">
            In accordance with the California Civil Code Section 1789.3 California resident Users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Platforms of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
            </p> 
        </div>
        </Fragment>
    )
}