import React from 'react';
import { Fragment } from 'react';

export default function Payment5(){
    return(
        <Fragment>
            <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                05.
            </span>
            Managing Payments 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ol>
                <li>
                <b>Tracking Incoming Payments:</b>
                <p className="paragraph p-adjustment text-left">
                To efficiently manage your finances, it's essential to keep a close eye on incoming payments within the Invoice Point dashboard. Here's how to stay updated on your financial transactions:
                <ul>
                    <li>
                    Log in to your Invoice Point account.
                    </li>
                    <li>
                    Access the payment section or dashboard.
                    </li>
                    <li>
                    Review the list of incoming payments to monitor their statuses and amounts.
                    </li>
                    <li>
                    Utilize filters and search options to find specific payments quickly.
                    </li>
                </ul>
                </p>
                </li>
                <li>
                <b>Marking Invoices as Paid:</b>
                <p className="paragraph p-adjustment text-left">
                Once online payment is received system will mark the invoice as paid. But it's crucial to promptly check the invoice status for accurate record-keeping.
                </p>
                </li>
                <li>
                <b>Handling Payment Discrepancies:</b>
                <p className="paragraph p-adjustment text-left">
                In the rare event of payment discrepancies or discrepancies in the invoice, follow the prescribed procedure to address and resolve the issue swiftly:
                <ul>
                    <li>
                    Reach out to the client promptly to discuss and clarify any discrepancies. Maintain clear and professional communication throughout the resolution process.
                    </li>
                    <li>
                    Verify payment records, including timestamps, transaction references, and payment method details, to identify and rectify any discrepancies or errors.
                    </li>
                    <li>
                    Keep comprehensive documentation of all communication, actions taken, and resolutions achieved during the discrepancy resolution process. This documentation can be valuable for future reference or dispute resolution, if necessary.
                    </li>
                </ul>
                </p>
                </li>
            </ol>
            </p>
            <p className="paragraph p-adjustment text-left">
            Efficiently managing payments and staying informed about incoming transactions is integral to your financial success. By following these steps and best practices, you can ensure a smooth and accurate payment handling process with Invoice Point.
            </p>
        </div>
        </Fragment>
    )
}