import React from 'react';
import { Fragment } from 'react';

export default function Terms17(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                13.
            </span>
            Termination and Suspension  
            </h4>
            <p className="paragraph p-adjustment text-left">
            This Agreement shall remain in effect until terminated by you or Invoice Point. Invoice Point may, in its sole discretion, at any time and for any or no reason, suspend or terminate these Terms with or without prior notice. These Terms will terminate immediately, without prior notice from Invoice Point, in the event that you fail to comply with any provision of these Terms. You may also terminate these Terms by deleting the App or stop using the Website and all copies thereof on your computer or other Device. Upon termination of these Terms, you shall cease all use of the App or the Website and delete all copies of the App from your Device. Termination of these Terms will not limit any of Invoice Point's rights or remedies at law or in equity in case of breach by you (during the term of these Terms) of any of your obligations under the present Terms.
            </p>
            <p className="paragraph p-adjustment text-left">
            We reserve the right to terminate these Terms and suspend or terminate your access to our Services without prior notice under any provision of these Terms. In this case you must stop accessing our Services immediately. We will not be liable to you or to any third party for such action. 
            </p>
            <p className="paragraph p-adjustment text-left">
            Upon any termination of these Terms you may no longer have access to your information and User Content in our App and on our Website. We may delete such information and files in your Account and prevent you from accessing them.
            </p>
            <p className="paragraph p-adjustment text-left">
            You agree that Invoice Point will not be liable to you or any third party for any termination of your access to our App, Website and Services.
            </p>
            <p className="paragraph p-adjustment text-left">
            We may, in our sole discretion, take appropriate legal action due to your breach of these Terms or any potentially fraudulent, abusive or illegal activity. These Terms will remain enforceable against you while your access to our Services is suspended and after it is terminated. Except for the access to our Services and all payment terms, all of the terms, conditions, and restrictions set forth in these Terms will survive the termination of the Terms.
            </p>
            <p className="paragraph p-adjustment text-left">
            We have the right to cooperate with any law enforcement authorities or court order that requires us to disclose information of anyone using our Services through the App or Website.
            </p>
        </div>
        </Fragment>
    )
}