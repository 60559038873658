import React from 'react';
import { Fragment } from 'react';

export default function Terms20(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                20.
            </span>
            No Warranties  
            </h4>
            <p className="paragraph p-adjustment text-left">
            The App and the Website are provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Invoice Point, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the App and the Website, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Invoice Point provides no warranty or undertaking and makes no representation of any kind that the App or the Website will meet your requirements, achieve any intended results, be compatible or work with any other software, systems, or services, operate without interruption, meet any performance or reliability standards or be error-free or that any errors or defects can or will be corrected. Without limiting the foregoing, neither Invoice Point nor any Invoice Point's provider makes any representation or warranty of any kind, express or implied: 
            <ol type='i'>
                <li>
                as to the operation or availability of the App or the Website, or the information, content, and materials or products included thereon;
                </li>
                <li>
                that the App and the Website will be uninterrupted or error-free;
                </li>
                <li>
                as to the accuracy, reliability, or currency of any information or content provided through the App and the Website; or
                </li>
                <li>
                that the App and the Website, its servers, the content, or e-mails sent from or on behalf of Invoice Point are free of viruses, scripts, trojan horses, worms, malware, time-bombs or other harmful components.
                </li>
            </ol>
            Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. 
            </p>
        </div>
        </Fragment>
    )
}