import React from 'react';
import { Fragment } from 'react';

export default function Terms35(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                35.
            </span>
            Contact Us 
            </h4>
            <p className="paragraph p-adjustment text-left">
            If you have any questions, don't hesitate to contact us:  
            </p> 
            <b>Invoice Point</b>
            <p className="paragraph p-adjustment text-left">
            <b>Address:</b> De Specerij 25, Almere, 1313 NC, Netherlands
            <br/>
            <b>Email:</b> <a href='mailto: support@invoicepoint.app'>support@invoicepoint.app</a>
            </p>
        </div>
        </Fragment>
    )
}