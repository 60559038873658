import React from 'react';
import { Fragment } from 'react';

export default function Terms25(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                25.
            </span>
            Changes and Updates to Our Terms  
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point reserves the right, at its sole discretion, to modify or replace these Terms at any time. We may change our Services and policies, and we may need to make changes to the Terms so that they accurately reflect our Services and policies. Unless otherwise required by law, we will do our best to notify you (for example, through our Services) before we make changes to these Terms and give you an opportunity to review them before they go into effect. By continuing to access or use our App and Website after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorised to use our App, Website or Services and can delete your Account.   
            </p>
        </div>
        </Fragment>
    )
}