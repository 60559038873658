import React from 'react';
import { Fragment } from 'react';

export default function Payment7(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                07.
            </span>
            Troubleshooting 
            </h4>
            <p className="paragraph p-adjustment text-left">
            <ol>
                <li>
                <b>Common Payment Issues:</b>
                <p className="paragraph p-adjustment text-left">
                <ul>
                    <li>
                    <b>Payment Failures:</b><br/>
                    Payment failures can be frustrating, but understanding the common reasons behind them can help resolve these issues efficiently. Some typical causes of payment failures include insufficient funds, expired cards, or incorrect card details. To troubleshoot payment failures, please consider the following steps:
                    <ul>
                        <li>
                        <b>Double-check the payment details:</b> Ensure that all payment information, including card numbers, expiration dates, and CVV codes, is entered correctly.
                        </li>
                        <li>
                        <b>Verify account balance:</b> Make sure your account has sufficient funds or credit available to cover the transaction amount.
                        </li>
                        <li>
                        <b>Contact your bank:</b> If the issue persists, reach out to your bank or financial institution to rule out any account-related problems or restrictions.
                        </li>
                        <li>
                        <b>Alternative payment methods:</b> Consider using alternative payment methods available within Invoice Point, such as PayPal or other supported options.
                        </li>
                    </ul>
                    </li>
                    <li>
                    <b>Disputed Payments:</b><br/>
                    Occasionally, disputes may arise between you and your clients regarding payments. It's essential to handle these situations professionally and efficiently. To address disputed payments, follow these steps:
                    <ul>
                        <li>
                        <b>Review the dispute details:</b> Carefully examine the nature of the dispute and any supporting documentation provided by the client.
                        </li>
                        <li>
                        <b>Communicate with your client:</b> Open a constructive dialogue with your client to resolve the issue amicably. Address their concerns and provide any requested information promptly.
                        </li>
                        <li>
                        <b>Mediation and resolution:</b> If a resolution cannot be reached directly with the client, consider involving a mediator or a third-party dispute resolution service, if applicable.
                        </li>
                    </ul>
                    </li>
                    <li>
                    <b>Refunds and Cancellations:</b><br/>
                    Handling refunds and cancellations is a standard part of business operations. To process refunds or cancellations accurately, adhere to the following procedures:
                    <ul>
                        <li>
                        <b>Review your refund policy:</b> Ensure that your refund and cancellation policies are clearly defined and in accordance with applicable laws and regulations.
                        </li>
                        <li>
                        <b>Communicate with the client:</b> If a client requests a refund or cancellation, promptly respond to their request and clarify the process involved.
                        </li>
                        <li>
                        <b>Process the refund:</b> If the request aligns with your policy, initiate the refund process through your Invoice Point account or payment provider. Provide the client with a confirmation of the refund.
                        </li>
                        <li>
                        <b>Document all transactions:</b> Maintain comprehensive records of all refund and cancellation transactions for your records and accounting purposes.
                        </li>
                    </ul>
                    </li>
                </ul>
                </p>
                </li>
                <li>
                    <b>Technical Problems:</b>
                    <ul>
                    <li>
                    <b>App-Related Issues:</b><br/>
                    If you encounter technical issues related to the application's functionality, follow these steps to address and resolve them:
                        <ul>
                            <li>
                            <b>Update the app:</b> Ensure that you are using the latest version of the Invoice Point app. Outdated versions may have bugs or compatibility issues.
                            </li>
                            <li>
                            <b>Clear cache and cookies:</b> Clearing cache and cookies on your device can resolve certain performance-related issues.
                            </li>
                            <li>
                            <b>Contact support:</b> If the problem persists, reach out to our dedicated support team, providing details about the issue, your device, and the app version you're using.
                            </li>
                        </ul>
                    </li>
                    <li>
                    <b>Integration Problems with Stripe:</b><br/>
                    In the event of integration difficulties with Stripe, follow these outlined steps for diagnosis and resolution:
                        <ul>
                            <li>
                            <b>Verify Stripe credentials:</b> Double-check your Stripe API keys and account settings to ensure they are correctly configured within your Invoice Point account.
                            </li>
                            <li>
                            <b>Check for outages:</b> Sometimes, temporary outages or maintenance on Stripe's end can disrupt integration. Monitor Stripe's status or contact their support for any known issues.
                            </li>
                            <li>
                            <b>Seek technical assistance:</b> If integration problems persist, don't hesitate to contact our support team. Our experts can provide guidance and assistance in resolving integration-related issues.
                            </li>
                        </ul>
                    </li>
                    <li>
                    <b>Contacting Support for Assistance:</b><br/>
                    If you have exhausted troubleshooting options and continue to experience issues, our dedicated support team is here to assist you promptly and expertly. Reach out to our support team through the designated contact channels within your Invoice Point account for personalized assistance and resolution.
                    </li>
                    </ul>
                </li>
            </ol>
            We are committed to ensuring a seamless and trouble-free experience with Invoice Point's payment system, and our support team is available to assist you every step of the way.
            </p>
        </div>
        </Fragment>
    )
}