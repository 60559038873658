import React from 'react';
import { Fragment } from 'react';

export default function Terms1(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold ">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                01.
            </span>
                Introduction and General Terms
            </h4>
            <p className="paragraph p-adjustment text-left">
            Welcome to our application Invoice Point (“App”) or website <a href='https://invoicepoint.app'>https://invoicepoint.app</a> (“Website”). Please carefully refer to the Terms and Conditions (hereinafter referred to as the “Terms”) before you begin your journey with us.
            </p>
            <p className="paragraph p-adjustment text-left">
            Invoice Point (hereinafter: “Invoice Point”, “we”, “our”, “us”) is a company registered in the Netherlands, with a corporate address: De Specerij 25, 1313NC Almere and registration number: 65776852, which manages the App, the Website and all the services provided therein (“Services”). These Terms & Conditions (“Terms”) govern your access to the Invoice Point App and Website and your use of them. This agreement contains crucial information regarding your rights, obligations, conditions, limitations and exclusions that might apply to you. 
            </p>
            <p className="paragraph p-adjustment text-left">
            These Terms apply to all users of the Invoice Point App and Website (together “you”, “your” or “Users”) unless stated otherwise.
            </p>
            <p className="paragraph p-adjustment text-left">
            By means of use, interactions, or purchasing any of our products, you are deemed to have agreed to and acknowledged these Terms and other policies even if the same has been communicated through a third party on your behalf. You may be denied access or use of the App if you do not agree to these Terms of Service or the Privacy Policy. Access the specified articles above for more specific policies concerning the activity and usage of the App. The Invoice Point Application is designed and intended for use by individuals and businesses seeking to streamline their invoice and estimate management processes. Our primary target audience includes freelancers, entrepreneurs, small and medium-sized enterprises (SMEs), and professionals seeking efficient and user-friendly tools for creating, customizing, and managing invoices and estimates. While our services are accessible to a broad user base, it is imperative that users understand the intended audience and utilize the Application in a manner consistent with its designed purpose. Invoice Point is not intended for use by individuals under the age of 18, and we do not knowingly collect personal information from users in this age group.
            </p>
            <p className="paragraph p-adjustment text-left">
            These Terms apply to the App, the entire Website and any email or other type of communication between you and Invoice Point. Under no circumstances shall Invoice Point or the Invoice Point team be liable for any direct, indirect, special, incidental, or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this Website, even if Invoice Point team or an authorized representative has been advised of the possibility of such damages. If your use of materials from the App or Website results in the need for servicing, repair, or correction of equipment or data, you assume any costs thereof. Invoice Point will not be responsible for any outcome that may occur during the course of usage of our resources.
            </p>
            <p className="paragraph p-adjustment text-left"> 
            These Terms contain an ARBITRATION PROVISION that requires all claims to be resolved by way of LEGALLY BINDING ARBITRATION. The terms of the arbitration provision are set forth in Article 28, “Agreement to Arbitrate, Dispute Resolution”, hereunder. 
            </p>
            <p className="paragraph p-adjustment text-left">
            By using our App, Website or any Services, you affirm that you are of legal age (according to your country's legislation) to enter into these Terms. If you are using our Services on behalf of an organization or company, you affirm that you have the legal authority to bind them to these Terms. 
            </p>
        </div>
        </Fragment>
    )
}