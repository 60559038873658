import React from 'react';
import { Fragment } from 'react';

export default function Terms30(){
    return(
        <Fragment>
        <div className="question mt-40">
            <h4 className="h-4 h-bold">
            <span style={{opacity:"0.4", marginRight:"10px"}}>
                30.
            </span>
            Promotions 
            </h4>
            <p className="paragraph p-adjustment text-left">
            Invoice Point may, from time to time, include Promotions that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and comply with all Promotions Rules. Additional terms and conditions may apply to purchases of goods or services on or through the Services. Such terms and conditions are made a part of these Terms by this reference. 
            </p> 
        </div>
        </Fragment>
    )
}