import React from 'react';
import Payment1 from './p01';
import Payment2 from './p02';
import Payment3 from './p03';
import Payment4 from './p04';
import Payment5 from './p05';
import Payment6 from './p06';
import Payment7 from './p07';
import Payment8 from './p08';
import Payment9 from './p09';
import Payment10 from './p10';
import Payment11 from './p11';

export default function OnlinePayments(){
    return(
        <section className="sp-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="h-1 h-bold text-left">
                        Online Payments
                        </h1>
                        <p className="paragraph p-adjustment text-left">
                        Last Updated on 2024-02-09
                        </p>
                        <p className="paragraph p-adjustment text-left">
                        Welcome to Invoice Point: Your Business's Financial Companion.
                        </p>
                        <p className="paragraph p-adjustment text-left">
                        At Invoice Point, we understand that your business demands control and efficiency. That's why we offer a comprehensive invoicing app designed to empower you to take charge of your financial transactions while nurturing your client relationships. With Invoice Point, you'll regain precious time to concentrate on what truly matters—building and growing your business.
                        </p>
                        <p className="paragraph p-adjustment text-left">
                        Invoice Point is a powerful invoicing app that simplifies the entire invoicing and payment collection process. Our aim is to help you streamline your financial transactions and enhance client relationships, allowing you to focus more on growing your business.
                        </p>
                        <p className="paragraph p-adjustment text-left">
                        Understanding the payment process is crucial for efficient financial management and client satisfaction. This guide will walk you through every aspect of using Invoice Point's payment system, ensuring you make the most of its capabilities.
                        </p>
                        <Payment1 />
                        <Payment2 />
                        <Payment3 />
                        <Payment4 />
                        <Payment5 />
                        <Payment6 />
                        <Payment7 />
                        <Payment8 />
                        <Payment9 />
                        <Payment10 />
                        <Payment11 />
                    </div>
                </div>
            </div>
        </section>
    )
}